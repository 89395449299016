import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Index1 from './Pages/Index1';
import About from './Pages/About';
import Team from './Pages/Team';
import ProductView from './Pages/ProductView';
import Shoplogin from './Pages/Shoplogin';
import Shopregister from './Pages/Shopregister';
import Subcategory from './Pages/Subcategory';
import SubcategoryProduct from './Pages/SubcategoryProduct';
import ShopProduct from './Pages/Shopproduct';
import Profile from './Pages/Profile';
import ChangePassword from './Pages/ChangePassword';
import Cart from './Pages/Cart';
import CartProduct from './Pages/CartProduct';
import ContactUs from './Pages/Contact';
import ProductList from './Pages/ProductList';
import RewardProductList from './Pages/RewardProductList';
import Checkout from './Pages/Checkout';
import Policy from './Pages/Policy';
import RewardProductView from './Pages/RewardProductView';
import RewardCartProduct from './Pages/RewardCartProduct';
import Favourite from './Pages/Favourite';
import RecentOrder from './Pages/RecentOrder';
import OrderList from './Pages/OrderList';
import OrderView from './Pages/OrderView';
import ResetPassword from './Pages/Resetpassword';
import Maintenance from './Pages/Maintenance';

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


function Markup(){
	
		return(
		// 	<><ToastContainer
		// 	position="top-right"
		// 	autoClose={5000}
		// 	hideProgressBar={false}
		// 	newestOnTop={false}
		// 	closeOnClick
		// 	rtl={false}
		// 	pauseOnFocusLoss
		// 	draggable
		// 	pauseOnHover
		// 	theme="light"
		//   />
			<BrowserRouter basename='/'>
				<Switch>
					<Route path='/' exact component={Index1} />
					<Route path='/aboutus' exact component ={About} />
					<Route path='/team' exact component ={Team} />
					<Route path='/subcategory/:id' component ={Subcategory} />
					<Route path='/subcategories/products/:id' component ={SubcategoryProduct} />
					<Route path='/product-details' exact component ={ShopProduct} />					
					<Route path='/productview/:id' exact component ={ProductView} />					
					<Route path='/login' exact component ={Shoplogin} />
					<Route path='/register' exact component ={Shopregister} />
					<Route path='/profile' exact component ={Profile} />
					<Route path='/changepassword' exact component ={ChangePassword} />
					<Route path='/cartlist' exact component ={Cart} />
					<Route path='/viewcart/:id/:cartid' exact component ={CartProduct} />
					<Route path='/viewcartreward/:id/:cartid' exact component ={RewardCartProduct} />
					<Route path='/contactus' exact component ={ContactUs} />
					<Route path='/products' exact component ={ProductList} />
					<Route path='/checkout' exact component ={Checkout} />
					<Route path='/privacypolicy' exact component ={Policy} />
					<Route path='/rewardproducts' exact component ={RewardProductList} />
					<Route path='/rewardproductview/:id' exact component ={RewardProductView} />					
					<Route path='/favourites' exact component ={Favourite} />
					<Route path='/recentorders' exact component ={RecentOrder} />
					<Route path='/orderlist' exact component ={OrderList} />
					<Route path='/order/:id' exact component ={OrderView} />
					<Route path='/resetpassword/:id' exact component ={ResetPassword} />
					<Route path='/maintenance' exact component ={Maintenance} />

					


				</Switch>
			</ BrowserRouter>
			// </>
		)
	
}

export default Markup;