import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
// import { toast } from 'react-toastify';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import {
	doLogin, forgotPwd,
	loginSelector,
} from "../../store/reducer/login";
import { API_STATUS } from "../../utils/constants";
import toast from 'react-hot-toast';
import SimpleReactValidator from 'simple-react-validator';

// import SimpleReactValidator from 'simple-react-validator';
var bnr = require('./../../images/banner/bnr3.jpg');

const Shoplogin = ({ history }) => {
	const formValidator = useRef(new SimpleReactValidator());
	const forgotValidator = useRef(new SimpleReactValidator());
	let alreadyLogged = localStorage.getItem('isAuthenticated');
	let temp_token = localStorage.getItem('temporaryToken');
	var host = window.location.protocol + "//" + window.location.host;
	// window.scroll({ top: 0, left: 0, behavior: "smooth", });
	if (alreadyLogged) {
		// alert(window.history.back());
		toast.dismiss();
		toast.success('User already logged in.!');
		// window.location.href = host
		window.setTimeout(function () {
			window.location.href = host
		}, 550);
	}

	const dispatch = useDispatch();
	// const history1 = useHistory();

	const { loading, forgotloading, errorMessage } = useSelector(loginSelector);
	// const { forgotloading } = useSelector(forgotSelector);
	// const formValidator = useRef(new SimpleReactValidator());

	const [loginDetails, setLoginDetails] = useState({ email: "", password: "", submitted: false, loading: false });
	const [forgotDetails, setForgotDetails] = useState({ email: "" });
	const [PasswordType, setPasswordType] = useState('password');
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const [ForgotFormSubmit, setForgotFormSubmit] = useState(false)

	const submitHandler = (e) => {
		e.preventDefault();
		// alert('ok');
		// history1.push("/");
	};
	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const onLoginSubmit = (e) => {
		e.preventDefault();
		setAddFormSubmit(true);
		let postData = loginDetails;
		postData.temp_token = temp_token

		if (formValidator.current.allValid()) {
			dispatch(doLogin({ postData }));
		}
		// console.log(postData, 'postData')

		// let errors = [];

		// if (postData.email == '') {
		// 	errors.push('Email');
		// }
		// if (postData.password == '') {
		// 	errors.push('Password');
		// }

		// if (errors.length > 0) {
		// 	toast.error(errors.toString() + ' is required.!');
		// 	return false;
		// }

		// if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(postData.email)) {
		// } else {
		// 	toast.error(' Please enter the vaild email .!');
		// 	return false;
		// }

	};

	useEffect(() => {
		formValidator.current.showMessages()
		forgotValidator.current.showMessages()
	}, [])

	const onForgotSubmit = (e) => {

		e.preventDefault();
		setForgotFormSubmit(true);
		let forgotData = forgotDetails;

		if (forgotValidator.current.allValid()) {
			dispatch(forgotPwd({ forgotData }));
		}

		// if (forgotData.email == '') {
		// 	toast.error(' Please enter the email .!');
		// 	return false;
		// }

		// if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(forgotData.email)) {
		// } else {
		// 	toast.error(' Please enter the vaild email .!');
		// 	return false;
		// }
	};

	useEffect(() => {
		console.log(loading, "loading")
		if (loading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Loggedin Successfully!");

			window.setTimeout(function () {
				window.location.href = '/'
			}, 1000);

		}
		if (loading === API_STATUS.PENDING) {
			console.log('erorr')

			//  toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (loading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error(errorMessage);
		}
	}, [loading]);

	useEffect(() => {
		// console.log(forgotloading,"forgotloading")
		if (forgotloading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Password reset link sent to your registered email.!");
			window.setTimeout(function () {
				window.location.href = '/login'
			}, 1000);
		} else if (forgotloading === API_STATUS.PENDING) {
			console.log('erorr')

			toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (forgotloading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error('Email not found.');
		}
	}, [forgotloading]);

	const togglePassword = () => {
		if (PasswordType == 'text') {
			setPasswordType('password')
		} else {
			setPasswordType('text')

		}
	}

	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Login</h1>

							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'} onClick={() => {
										window.scroll({ top: 0, left: 0, behavior: "smooth", });
									}}>Home</Link></li>
									<li>Login</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="section-full content-inner shop-account" style={{ paddingTop: '33px' }}>
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<h2 className="m-t0 m-b40 m-md-b20">Already Registered?</h2>
							</div>
						</div>
						<div className="row align-content-stretch">
							<div className="col-lg-6 col-md-12 m-b30">
								<div className="p-a30 border-1 h100">
									<div className="tab-content">
										<h3 className="m-b10">New Customer</h3>
										<p className="m-b15">By creating an account with our store, you will be able to move through the checkout process faster, store multiple shipping addresses, view and track your orders in your account and more.</p>
										<Link to={'/register'} className="btn btnhover" >Create An Account</Link>
									</div>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 m-b30">
								<div className="p-a30 border-1 radius-sm">
									<div className="tab-content tab-form nav">
										<TabContent activeTab={activeTab}>
											<TabPane tabId="1">
												<form id="login" onSubmit={(e) => submitHandler(e)} className="tab-pane active col-12 p-a0">
													<h3 className="m-b5">Login</h3>
													<p>If you have an account with us, please log in.</p>
													<div className="form-group">
														<label >E-MAIL <span style={{ color: 'red' }}>*</span></label>
														<input
															className="form-control"
															id="signin-email"
															placeholder="Email"
															type="email"
															name='email'
															value={loginDetails.email}
															autoComplete="off"
															onChange={val => {
																setLoginDetails({ ...loginDetails, email: val.target.value });
															}}
														/>
														{formValidator.current.message(
															'email',
															loginDetails.email,
															'required|email',
															{
																className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter E-mail',
																	email: 'Please Enter Valid E-mail',
																}
															}
														)}
													</div>
													<div className="form-group">
														<label>Password <span style={{ color: 'red' }}>*</span></label>
														<div className='position-relative'>
															<input
																className="form-control"
																id="signin-password"
																name='password'
																placeholder="Password"
																type={PasswordType}
																value={loginDetails.password}
																autoComplete="off"
																onChange={val => {
																	setLoginDetails({ ...loginDetails, password: val.target.value });
																}}
															/> <span style={{ position: 'absolute', top: '11px ', right: '15px' }}>
																{(PasswordType === 'password') ? <i onClick={togglePassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
																	< i onClick={togglePassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
															</span>
														</div>
														{formValidator.current.message(
															'password',
															loginDetails.password,
															'required',
															{
																className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter Password'
																}
															}
														)}
													</div>
													<div className="text-left">

														<button
															className="btn btn-primary btn-lg btn-block1"
															href="javascript:void(0);" onClick={onLoginSubmit}
														>Login</button> &nbsp;&nbsp;
														<Link to={"#"} data-toggle="tab" id="#forgot-password"
															className={classnames({ active: activeTab === '1' }) + ' btn blue'}
															onClick={() => { toggle('2'); }}
														><i className="fa fa-unlock-alt"></i> Forgot Password</Link>
													</div>

												</form>
											</TabPane>
											<TabPane tabId="2">
												<form id="forgot-password"
													onSubmit={(e) => submitHandler(e)}
													className={activeTab === '2' ? 'tab-pane fade col-12 p-a0  show' : ' tab-pane fade col-12 p-a0 '}>
													<h4>Forgot Password ?</h4>
													<p>We will send you an email to reset your password. </p>
													<div className="form-group">
														<label>E-Mail <span style={{ color: 'red' }}>*</span></label>
														<input
															className="form-control"
															id="signin-email"
															placeholder="Email"
															type="email"
															value={forgotDetails.email}
															autoComplete="off"
															onChange={val => {
																setForgotDetails({ ...forgotDetails, email: val.target.value });
															}}
														/>
														{forgotValidator.current.message(
															'email',
															forgotDetails.email,
															'required|email',
															{
																className: `invalid_feedback
														  ${ForgotFormSubmit ? 'show' : 'hide'}`,
																messages: {
																	required: 'Please Enter E-mail',
																	email: 'Please Enter Valid E-mail',
																}
															}
														)}
													</div>
													<div className="text-left gray-btn">
														<Link
															className={classnames({ active: activeTab === '2' }) + ' btn  gray'}
															onClick={() => { toggle('1'); }}
															data-toggle="tab" to={"#"} >Back</Link>
														<button type="submit" className="btn btnhover pull-right" onClick={onForgotSubmit}>Submit</button>
													</div>
												</form>
											</TabPane>
										</TabContent>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)

}

export default Shoplogin;