import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";
import loginReducer from "./reducer/login";
import signupReducer from "./reducer/signup";
import categoryReducer from "./reducer/category";
import subcategoryReducer from "./reducer/subcategory";
import subcategoryProductReducer from "./reducer/subcategoryproducts";
import ProductReducer from "./reducer/product";
import ProfileReducer from "./reducer/profile";
import CustemMenuReducer from "./reducer/custommenu";
import ItemSizeReducer from "./reducer/itemsize";
import ContactReducer from "./reducer/contactus";
import FavouriteReducer from "./reducer/favourite";
import GeneralReducer from "./reducer/general";
import OrderReducer from "./reducer/order";

export const reducer = {
  login: loginReducer,
  signup: signupReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  subcategoryproducts: subcategoryProductReducer,
  product: ProductReducer,
  profile: ProfileReducer,
  custommenu: CustemMenuReducer,
  itemsize: ItemSizeReducer,
  contactus: ContactReducer,
  favourite: FavouriteReducer,
  general: GeneralReducer,
  order: OrderReducer,
};

const store = configureStore({
  reducer,
  // middleware: [ // Because we define the middleware property here, we need to explictly add the defaults back in.
  //     ...getDefaultMiddleware(),
  // ]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
