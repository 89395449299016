import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Product from './Product';
// import { allProductsData, ProductSelector } from '../../store/reducer/product';
import { getPosData, subcategoryProductData, subcategoryProductSelector } from '../../store/reducer/subcategoryproducts';
import { useDispatch, useSelector } from "react-redux";
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { secretKey } from '../../services/config';
import { Input, Space } from 'antd';
import moment from 'moment'
import { generalData, generalSelector } from "../../store/reducer/general";
import 'moment-timezone';

function SubcategoryProduct() {

	let {id} = useParams();
	id = EncryptDecrypt.decryptdata(id, secretKey);
    console.log(id, 'subcategoryid');
    const dispatch = useDispatch();
	// const {allProductDatas} = useSelector(ProductSelector);
	const {subcategoryProductDatas, posDatas} = useSelector(subcategoryProductSelector);
	const [fromTime, setFromTime] = useState(0);
	const [toTime, setToTime] = useState(0);
	const { Search } = Input;
	const { generalDatas } = useSelector(generalSelector);
	const [timezone, setTimezone] = useState('America/Chicago');
	const subcategoryProductDispatch = (id) => {

        dispatch(
            subcategoryProductData({
                subcategoryid: id
            })
        );
    };

	const posDispatch = () => {
		console.log('inside comp posLoading');

        dispatch(
            getPosData({})
        );
    };

	const onSearch = (searchQuery) => {
		console.log(searchQuery, 'searchQuery');

		dispatch(
			subcategoryProductData({
                subcategoryid: id,
				query: searchQuery
            })
		);
	}

	useEffect(() => {
        subcategoryProductDispatch(id)
		posDispatch()
    }, [])


	useEffect(() => {
		if(subcategoryProductDatas){
			console.log(subcategoryProductDatas, 'subcategory Product Datas');
		}

		if (generalDatas && generalDatas.length) {
			setTimezone(generalDatas[0].timezone)
		}

		if (posDatas) {

			let endtime = '';
			let starttime = ''
			let showfromtime = 0;
			let showtotime = 0

			if (posDatas.data[0].start_time !== '' && posDatas.data[0].end_time !== '' && posDatas.data[0].schedule == 1) {
				let from_array = posDatas.data[0].start_time.split(' ') // AM,PM
				let to_array = posDatas.data[0].end_time.split(' ')

				starttime = from_array[0].split(':') //01, 22
				endtime = to_array[0].split(':')

				let frommoment = moment().tz(timezone).format('LT').split(' ')

				let restricttime = frommoment[0].split(':')
				let currenttime = restricttime[0];

				if (restricttime[0].length == 1) {
					currenttime = '0' + restricttime[0];
				}

				var timenow = parseInt(currenttime);
				var startnow = parseInt(starttime[0])
				var endnow = parseInt(endtime[0])
				
				if(from_array[1] === to_array[1]){
					if(frommoment[1] !== from_array[1]){
						showfromtime = 0;
						showtotime = 0;
					}else{
					
						if(startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1;
							// showtotime = 1;
						}
						
						if(startnow === timenow && (parseInt(starttime[1]) > parseInt(restricttime[1]))) {
							showfromtime = 0;
						}

						if(endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1;
							// showfromtime = 1;

						}

						if(endnow === timenow && (parseInt(endtime[1]) < parseInt(restricttime[1]))) {
							showtotime = 0;
						}

						if(startnow !== timenow){

							if( (startnow !== 12 && startnow < timenow)){
								showfromtime = 1;
							}

							if(startnow === 12 && startnow > timenow ){
								showfromtime = 1;
							}
						}
						if(endnow !== timenow){


							if(timenow !== 12 && (endnow > timenow)){
								showtotime = 1;
							}

							if(timenow === 12 && (endnow < timenow)){
								showtotime = 1;
							}
						}

					}
				}

				if (from_array[1] === 'AM' && to_array[1] === 'PM') {
					showfromtime = 0;
					showtotime = 0;

					if (frommoment[1] === 'AM') {
						showtotime = 1;
						if(parseInt(starttime[0]) === 12 && parseInt(restricttime[0]) === 12){

							if( parseInt(starttime[1]) <= parseInt(restricttime[1]) ){
								showfromtime = 1	
							}else{
								showfromtime = 0
							}
						}else if( parseInt(starttime[0]) === 12){
							showfromtime = 1
						}else{
							if( parseInt(starttime[0]) <= parseInt(restricttime[0])){
								showfromtime = 1
								if( parseInt(starttime[0]) === parseInt(restricttime[0]) && parseInt(starttime[1]) <= parseInt(restricttime[1])  ){
									showfromtime = 1	
								}
							}
						}
					}

					if (frommoment[1] === 'PM') {
						showfromtime = 1;
						if(parseInt(endtime[0]) === 12 && parseInt(restricttime[0]) === 12){
							
							if( parseInt(endtime[1]) >= parseInt(restricttime[1]) ){
								showtotime = 1	
							}else{
								showtotime = 0
							}
						}else if(parseInt(restricttime[0]) === 12){
							showtotime = 1
						}else{
							if(parseInt(endtime[0]) >= parseInt(restricttime[0]) || parseInt(restricttime[0])=== 12){
								showtotime = 1
								if( parseInt(endtime[0]) === parseInt(restricttime[0]) && parseInt(endtime[1]) >= parseInt(restricttime[1])  ){
									showtotime = 1	
								}
							}
						}
					}

				}

				if(from_array[1] === 'PM' &&  to_array[1] === 'AM'){

					if(frommoment[1] === 'PM' &&  startnow <= timenow ){
						showfromtime = 1
						showtotime = 1
						if(startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))){
							showfromtime = 1
						}

						if(startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))){
							showfromtime = 0
						}
					}

					if(frommoment[1] === 'AM' &&  endnow >= timenow ){
						showtotime = 1
						showfromtime = 1
						if(endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))){
							showtotime = 1
						}

						if(endnow === timenow && (parseInt(endtime[1]) <= parseInt(restricttime[1]))){
							showtotime = 0
						}
					}
				}

			}

			setFromTime(showfromtime)
			setToTime(showtotime)
		}

	}, [subcategoryProductDatas, posDatas, generalDatas])

	// useEffect(() => {
    //     // subcategoryProductDispatch(id)
	// 	console.log(posLoading, 'posLoading');

	// 	if(posDatas){
	// 		console.log(posDatas, 'POSDatas');
	// 	}
		
    // }, [posLoading])

console.log(posDatas , 'posDatas');


		return(
			<>
			<Header /> 
			
			<div className="page-content bg-white">
				
				{ (subcategoryProductDatas && subcategoryProductDatas.data) ? 
							<div className="breadcrumb-row" style={{ backgroundColor:'#c1272d', paddingLeft:'20px' }}>
								<ul className="list-inline">
									<li><Link to={`/`} style={{ color:'white' }}>Home</Link></li>
									<li>{ (subcategoryProductDatas && subcategoryProductDatas.result)  ? ((subcategoryProductDatas.result.rows) ? <Link to={`/subcategory/${EncryptDecrypt.encryptdata(subcategoryProductDatas.result.rows[0].category.id, secretKey)}`} style={{ color:'white' }}>{subcategoryProductDatas.result.rows[0].category.name} </Link> : ''): ''  }</li>
									<li>{ (subcategoryProductDatas && subcategoryProductDatas.result)  ? ((subcategoryProductDatas.result.rows) ? <Link to={'#'} style={{ color:'white' }}>{subcategoryProductDatas.result.rows[0].name}</Link> : '' ) : '' }</li>
								</ul>
				</div>: '' }
				{
					(posDatas && posDatas.data ) ?  ((posDatas && posDatas.data[0].schedule==0  || !fromTime && !toTime) ? 
			
				<div className="tab-content product_list">
					<div id="web-design-1" className="tab-pane active">
						<p className="m-b0"> { posDatas.data[0].message } </p> :  '' 
					</div>
				</div> 
				: '' ) : ''
				}
				<br/>

				<div className='row'>
				<div className="col-lg-8 col-md-9 col-sm-9"></div>
				<div className="col-lg-4 col-md-3 col-sm-3">
					<Search placeholder="Search by product name"  size="large" onSearch={onSearch} enterButton />
				</div>
				</div>
					<Product />

			</div>
			<Footer  />
			
			</>
		)
	
}

export default SubcategoryProduct;