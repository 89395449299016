import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCardType, validateGiftCard, generalList, generalUpdate } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
// import alertmessage from '../../utils/alert';
const namespace = "general";

const initialState = {
    generalLoading: "initial",
    generalDatas: null,
    giftcardLoading: "initial",
    giftcardDatas: null,
    cardtypeLoading: "initial",
    cardtypeDatas: null,
};

export const generalData = createAsyncThunk(
    `${namespace}/generalData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(generaltData, secretKey)
            const data = await generalList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const CheckGiftCard = createAsyncThunk(
    `${namespace}/CheckGiftCard`,
    async ({ giftcard = ""}, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(generaltData, secretKey)
            const data = await validateGiftCard({payload: {gift_card: giftcard}});
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const CardType = createAsyncThunk(
    `${namespace}/CardType`,
    async ({ card_id = "" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(generaltData, secretKey)
            const data = await getCardType({payload: { id: card_id }});
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const generalSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        cleargeneralLoadingDatas: (state) => {
            state.generalLoading = "initial";
        },
    },
    extraReducers: {
        [generalData.pending](state) {
            state.generalLoading = API_STATUS.PENDING;
        },
        [generalData.fulfilled](state, { payload }) {
            state.generalLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(EncryptDecrypt.apidecryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.response,"general payload")
            state.generalDatas = payloaddatas?.response;
        },
        [generalData.rejected](state, action) {
            state.generalLoading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                // alertmessage.sweetalert();
            }
            // state.errorMessage = action?.payload?.data;
        },
        [CheckGiftCard.pending](state) {
            state.giftcardLoading = API_STATUS.PENDING;
        },
        [CheckGiftCard.fulfilled](state, { payload }) {
            state.giftcardLoading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            let payloaddatas = payload;//JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            console.log(payloaddatas?.response,"general payload")
            state.giftcardDatas = payloaddatas?.data;
        },
        [CheckGiftCard.rejected](state, action) {
            state.giftcardLoading = API_STATUS.REJECTED;
            if(action?.payload?.data?.auth==="deleted"){
                // alertmessage.sweetalert();
            }
            // state.errorMessage = action?.payload?.data;
        },
        [CardType.pending](state) {
            state.cardtypeLoading = API_STATUS.PENDING;
        },
        [CardType.fulfilled](state, { payload }) {
            state.cardtypeLoading = API_STATUS.FULFILLED;
            state.cardtypeDatas = payload?.data;
        },
    },
});

export const { clearData, clearLoadingDatas } = generalSlice.actions;

export const generalSelector = (state) => state.general;

export default generalSlice.reducer;
