import React, {  useEffect} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import {Link} from 'react-router-dom';
import Slider from './../Element/Slider';
import { categoryData, categorySelector } from '../../store/reducer/category';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from "../../services/config";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import Owl from '../Element/Owl';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import toast from 'react-hot-toast';
//Images	
var img1 = require('./../../images/background/bg5.jpg');


function Index1(){
	// toast.success('Sign up successfully.!');
	window.scroll({ top: 0, left: 0, behavior: "smooth", });
	// console.log(localStorage.getItem('isAuthenticated'), 'auth')
	const dispatch = useDispatch();
	const {categoryDatas} = useSelector(categorySelector);

	const categoryDispatch = () => {
        dispatch(
            categoryData({
                // query: searchQuery,
                // page: page + 1,
                // limit: rowsPerPage,
                // sortby: sortBy,
                // order: order,
            })
        );
    };


	useEffect(() => {
        categoryDispatch()
    }, [])
// console.log(categoryDatas, 'categoryDatas');
	// render(){
		return(
			<>
				<Header />
			
				<div className="page-content bg-white">
					<div className="content-block">
						<Slider />
						<div className="section-full content-inner-3" style={{backgroundImage:"url('" + img1 + "')",  backgroundSize:"100%" }}>
							<div className="container">
								<div className="row service-area1">
									
									{ (categoryDatas && categoryDatas.data) ?  categoryDatas.data.map((result, index) => {
										
										return <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-center-block">
											<a href={(`/subcategory/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)} >
										<div className="icon-bx-wraper text-center service-box1" style={{backgroundImage: "url('" + assestURL + result.image + "')", marginTop:'15px', backgroundRepeat:'round'}}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">{ result.name } </h2>
												<p>{ result.description }</p>
												<div className="dlab-separator style1 bg-primary"></div>

												{/* <Link to={(`/product-details`)} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link> */}
												<Link to={(`/subcategory/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)} className="btn btnhover" onClick={() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth", }); }}>More details <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div></a>
									</div>
									})
									: '' }

								</div>

								

								
							</div>
						</div>	
					</div>		
				</div><br></br>
				<h2 style={{ marginLeft: '30px' }}>&nbsp;&nbsp;&nbsp;Recent Products</h2>
				<Owl/>
				<Footer />
			</>	
		)
	// }
} 

export default Index1;