import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContactList, getAboutList } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "contactus";

const initialState = {
    contactLoading: "initial",
    contactMessage: null,
    contactDatas: null,
    aboutLoading: "initial",
    aboutMessage: null,
    aboutDatas: null,
};

export const contactUsData = createAsyncThunk(
    `${namespace}/contactus`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
            const data = await getContactList();
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const aboutData = createAsyncThunk(
    `${namespace}/aboutus`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
            const data = await getAboutList();
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);



const contactSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [contactUsData.pending](state) {
            state.contactLoading = API_STATUS.PENDING;
        },
        [contactUsData.fulfilled](state, { payload }) {
            state.contactLoading = API_STATUS.FULFILLED;
            console.log({ payload });
            state.contactDatas = payload?.data;
        },
        [contactUsData.rejected](state, action) {
            state.contactLoading = API_STATUS.REJECTED;
            state.contactMessage = action?.payload?.data;
        },
        [aboutData.pending](state) {
            state.aboutLoading = API_STATUS.PENDING;
        },
        [aboutData.fulfilled](state, { payload }) {
            state.aboutLoading = API_STATUS.FULFILLED;
            console.log({ payload });
            state.aboutDatas = payload?.data;
        },
        [aboutData.rejected](state, action) {
            state.aboutLoading = API_STATUS.REJECTED;
            state.aboutMessage = action?.payload?.data;
        },
    },
});

export const { clearData } = contactSlice.actions;

export const ContactSelector = (state) => state.contactus;

export default contactSlice.reducer;
