import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Carousel } from 'antd';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { recentProductsData, ProductSelector } from '../../store/reducer/product';
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../utils/constants";
import { assestURL, secretKey } from '../../services/config';
import Slider from "react-slick";
import { addfavouriteProductCart, favouriteSelector } from '../../store/reducer/favourite';
import { toast } from 'react-hot-toast';


 function Owl(){

	const {favDatas, favloading, favmessage} = useSelector(favouriteSelector);
	const { recentProductDatas , recentProductLoading} = useSelector(ProductSelector);
    const dispatch = useDispatch();
	let temp_token = localStorage.getItem('temporaryToken');
	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	const productDispatch = () => {

		dispatch(
			recentProductsData({})
		);
	}

	useEffect(() => {
		productDispatch()
	}, [])

	useEffect(() => {
console.log(recentProductDatas,'recentProductLoading');
		if(recentProductLoading == API_STATUS.FULFILLED){
			console.log(recentProductDatas,"new recentProductDatas")

		}
		
		
	  }, [recentProductLoading, recentProductDatas]);

	// let sliderlength = 3
	// if(recentProductDatas?.data){
	// 	sliderlength = recentProductDatas?.data.length;//recentProductDatas?.data.length
	// }
	var settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		
		};

		const addFavtoCart = (id) => {

			dispatch(
				addfavouriteProductCart({
					productid : id,
					temp_token: temp_token
				})
			);
		}

		useEffect(()=>{
			if (favloading === API_STATUS.FULFILLED) {
				toast.dismiss();
				toast.success(favmessage);
				setTimeout(() => {
					window.location.href = '/cartlist'
				}, 1000);
			} else if (favloading === API_STATUS.REJECTED) {
				toast.dismiss();
				toast.success(favmessage);
			}
			
		}, [favloading])

		console.log(recentProductDatas, 'last recentProductDatas');
		console.log(recentProductDatas?.data, 'last recentProductDatas data');
	return (
		<Slider {...settings}>
			{ (recentProductDatas && (recentProductDatas.data && recentProductDatas.data.length)) ? recentProductDatas.data.map((result, index) => {

			return <><div> <h3>
				<div className="p-a15s mx-5" >
					<div className="item-box shop-item">
						<div className="card cust-card">
							<div className='img-wrapper position-relative'>
							<img className='recent-img-product' src={assestURL + result.image} alt="" />
						{
							<Link to={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btnhover cart-btns"><i className="ti-eye m-r5"></i> View Product</Link> 
							 
						}	
							</div>
							<div className="py-3 text-center d-flex justify-content-between align-items-center px-2">
							<h4 className="item-title font-weight-nornal h6">{(result.reward == '1') ? <a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`}> { result.name }</a> : <a href={`/productview/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} >{ result.name }</a>}</h4>

						<h4 className="item-title">
						{
							(result.reward == '1') ? <span className=''>
								{result.price.find(o => o.itemsize_id === result.default_size).points} pts</span> 
							: 
							<span className='text-secondary-color' >
								$ {parseFloat(result.price.find(o => o.itemsize_id === result.default_size).price).toFixed(2)}</span> 
						}
						</h4>
						</div>
						</div>
				
					</div>	
				</div> </h3>
			</div></>
			}) : ""}

    </Slider>
	)
				
}	

export default Owl;