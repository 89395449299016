import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { Button, Modal } from "react-bootstrap";
import { updateQuantityData, cartData, RemoveFromCart, ProductSelector } from '../../store/reducer/product';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from '../../services/config';
import { toast } from 'react-hot-toast';
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { getPosData, subcategoryProductSelector } from '../../store/reducer/subcategoryproducts';
import moment from 'moment'
import { doLogin, forgotPwd, loginSelector } from "../../store/reducer/login";
import SimpleReactValidator from 'simple-react-validator';
import { Alert } from 'antd';
import classnames from 'classnames';
import { TabContent, TabPane, } from 'reactstrap';
import customizedLabel from "../../images/gallery/tag.png"
import rewardLabel from "../../images/gallery/tag2.png"
import { generalData, generalSelector } from "../../store/reducer/general";
import 'moment-timezone';
import { Select, Space } from 'antd';
import { profileData, profileUpdateData, profileSelector } from "../../store/reducer/profile";
import { Badge, Card } from 'antd';

var img1 = require('./../../images/background/bg5.jpg');

function Cart() {
	// render(){
	// window.scrollTo(0, 0)
	const formValidator = useRef(new SimpleReactValidator());
	const dispatch = useDispatch();
	let userDetails = JSON.parse(localStorage.getItem('userdata'));
	const [loginUser] = useState(userDetails && userDetails.id);
	let temp_token = localStorage.getItem('temporaryToken');
	const { cartDatas, removeLoading, removeMessage, quantityUpdated } = useSelector(ProductSelector);
	const { posDatas } = useSelector(subcategoryProductSelector);
	const { loading, forgotloading, errorMessage } = useSelector(loginSelector);
	const { generalDatas } = useSelector(generalSelector);
	const [cartID, setcartID] = useState(null);
	const [subTotal, setSubTotal] = useState(0);
	const [orderTotal, setOrderTotal] = useState(0);
	const [rewardTotal, setRewardTotal] = useState(0);
	const [showModal, setshowModal] = useState(false);
	const [showLoginModal, setshowLoginModal] = useState(false);
	const [removecartID] = useState({ id: "" });
	const [num, setNum] = useState(1);
	const [fromTime, setFromTime] = useState(0);
	const [toTime, setToTime] = useState(0);
	const [loginDetails, setLoginDetails] = useState({ email: "", password: "", submitted: false, loading: false });
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const [emailMessage, setemailMessage] = useState('')
	const [passwordMessage, setpasswordMessage] = useState('')
	const [loader, setloader] = useState(0);
	const [PasswordType, setPasswordType] = useState('password');
	const [forgotDetails, setForgotDetails] = useState({ email: "" });
	const [salesTax, setsalestax] = useState(0);
	const [dayFormat, setdayFormat] = useState('');
	const [dateFormat, setdateFormat] = useState('');
	const [timeArray, settimeArray] = useState([]);
	const [timezone, setTimezone] = useState('America/Chicago');
	const { profileDatas, updateLoading, profileerrorMessage } = useSelector(profileSelector);
	const [driveDetails, setdriveDetails] = useState({ vehicleModel: "", vehicleColor: "", vehicleNo: "" });
	const { Option } = Select;
	const [rewardPoints, setrewardPoints] = useState([])
	const [addCartTrue, setaddCartTrue] = useState(false);
	const [showRewardMessage, setShowRewardMessage] = useState('');
	const [totalCost, setTotalCost] = useState(0);
	const [rewardProducts, setrewardProducts] = useState([])
	const [cartProducts, setcartProducts] = useState([])


	const getProfileDispatch = (loginUser) => {
		if (loginUser) {
			dispatch(
				profileData({ user_id: loginUser })
			);

		}
	};

	useEffect(() => {
		getProfileDispatch(loginUser)
	}, [loginUser])

	useEffect(() => {
		if (profileDatas) {
			setrewardPoints([profileDatas.reward_points])
		}
	}, [profileDatas])

	useEffect(() => {

		if ((cartDatas && rewardPoints.length > 0)) {
			let data = cartDatas.data && cartDatas.data.length && cartDatas.data.filter((item) => {
				if (item.reward == 1)
					return item
			})
			if (data.length) {
				let total_Cost = 0;

				if (data.length == 1) {
					total_Cost = Number(data[0].total_cost);
				} else if (data.length > 1) {
					total_Cost = data.map(item => item.total_cost).reduce((prev, next) => Number(prev) + Number(next));
				}
				if (total_Cost > rewardPoints[0]) {
					setaddCartTrue(true)
				} else {
					setaddCartTrue(false)
				}
			}

		}

	}, [cartDatas, rewardPoints.length > 0])

	const submitHandler = (e) => {
		e.preventDefault();
	};
	const handleChange = (value) => {
		console.log(`selected ${value}`);
	};


	useEffect(() => {


		if (posDatas) {

			let endtime = '';
			let starttime = ''
			let showfromtime = 0;
			let showtotime = 0

			if (posDatas.data[0].start_time !== '' && posDatas.data[0].end_time !== '' && posDatas.data[0].schedule == 1) {
				let from_array = posDatas.data[0].start_time.split(' ') // AM,PM
				let to_array = posDatas.data[0].end_time.split(' ')

				starttime = from_array[0].split(':') //01, 22
				endtime = to_array[0].split(':')

				let frommoment = moment().tz(timezone).format('LT').split(' ')

				let restricttime = frommoment[0].split(':')
				let currenttime = restricttime[0];

				if (restricttime[0].length == 1) {
					currenttime = '0' + restricttime[0];
				}

				var timenow = parseInt(currenttime);
				var startnow = parseInt(starttime[0])
				var endnow = parseInt(endtime[0])

				if (from_array[1] === to_array[1]) {
					if (frommoment[1] !== from_array[1]) {
						showfromtime = 0;
						showtotime = 0;
					} else {

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1;
							// showtotime = 1;
						}

						if (startnow === timenow && (parseInt(starttime[1]) > parseInt(restricttime[1]))) {
							showfromtime = 0;
						}

						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1;
							// showfromtime = 1;

						}

						if (endnow === timenow && (parseInt(endtime[1]) < parseInt(restricttime[1]))) {
							showtotime = 0;
						}

						if (startnow !== timenow) {

							if ((startnow !== 12 && startnow < timenow)) {
								showfromtime = 1;
							}

							if (startnow === 12 && startnow > timenow) {
								showfromtime = 1;
							}
						}
						if (endnow !== timenow) {


							if (timenow !== 12 && (endnow > timenow)) {
								showtotime = 1;
							}

							if (timenow === 12 && (endnow < timenow)) {
								showtotime = 1;
							}
						}

					}
				}

				if (from_array[1] === 'AM' && to_array[1] === 'PM') {
					showfromtime = 0;
					showtotime = 0;

					if (frommoment[1] === 'AM') {
						showtotime = 1;
						if(parseInt(starttime[0]) === 12 && parseInt(restricttime[0]) === 12){

							if( parseInt(starttime[1]) <= parseInt(restricttime[1]) ){
								showfromtime = 1	
							}else{
								showfromtime = 0
							}
						}else if( parseInt(starttime[0]) === 12){
							showfromtime = 1
						}else{
							if( parseInt(starttime[0]) <= parseInt(restricttime[0])){
								showfromtime = 1
								if( parseInt(starttime[0]) === parseInt(restricttime[0]) && parseInt(starttime[1]) <= parseInt(restricttime[1])  ){
									showfromtime = 1	
								}
							}
						}
					}

					if (frommoment[1] === 'PM') {
						showfromtime = 1;
						if(parseInt(endtime[0]) === 12 && parseInt(restricttime[0]) === 12){
							
							if( parseInt(endtime[1]) >= parseInt(restricttime[1]) ){
								showtotime = 1	
							}else{
								showtotime = 0
							}
						}else if(parseInt(restricttime[0]) === 12){
							showtotime = 1
						}else{
							if(parseInt(endtime[0]) >= parseInt(restricttime[0]) || parseInt(restricttime[0])=== 12){
								showtotime = 1
								if( parseInt(endtime[0]) === parseInt(restricttime[0]) && parseInt(endtime[1]) >= parseInt(restricttime[1])  ){
									showtotime = 1	
								}
							}
						}
					}

				}

				if (from_array[1] === 'PM' && to_array[1] === 'AM') {

					if (frommoment[1] === 'PM' && startnow <= timenow) {
						showfromtime = 1
						showtotime = 1
						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 1
						}

						if (startnow === timenow && (parseInt(starttime[1]) <= parseInt(restricttime[1]))) {
							showfromtime = 0
						}
					}

					if (frommoment[1] === 'AM' && endnow >= timenow) {
						showtotime = 1
						showfromtime = 1
						if (endnow === timenow && (parseInt(endtime[1]) >= parseInt(restricttime[1]))) {
							showtotime = 1
						}

						if (endnow === timenow && (parseInt(endtime[1]) <= parseInt(restricttime[1]))) {
							showtotime = 0
						}
					}
				}

			}

			setFromTime(showfromtime)
			setToTime(showtotime)
		}

	}, [posDatas])

	const onCloseCart = () => {
		setshowModal(false);
		setcartID(null)
	}
	const onCloseLoginCart = () => {
		setshowLoginModal(false);
		// setcartID(null)
	}


	const PosDispatch = () => {
		dispatch(
			getPosData({})
		);
	};

	const [activeTab, setActiveTab] = useState('1');
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const onForgotSubmit = () => {
		let forgotData = forgotDetails;
		console.log(forgotData, "forgotData")


		if (forgotData.email == '') {
			toast.error(' Please enter the email .!');
			return false;
		}

		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(forgotData.email)) {
		} else {
			toast.error(' Please enter the vaild email .!');
			return false;
		}

		dispatch(forgotPwd({ forgotData }));
	};

	useEffect(() => {
		// console.log(forgotloading,"forgotloading")
		if (forgotloading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Password reset link sent to your registered email.!");
			//   history.push('/login');
			setshowLoginModal(false);
			window.scrollTo(0, 0)
		} else if (forgotloading === API_STATUS.PENDING) {
			console.log('erorr')

			toast.dismiss();
			//  toast.error('Login Failed! Please check username and password');
		}
		if (forgotloading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error('Email not found.');
		}
	}, [forgotloading]);

	useEffect(() => {
		PosDispatch()
	}, [])

	const onRemoveCart = () => {
		let postData = removecartID
		postData.id = cartID
		// postData.temp_token = localStorage.getItem('temporaryToken')
		setshowModal(false);
		setcartID(null)

		// toast.success('Item has been removed successfully.!');
		dispatch(RemoveFromCart({ postData }));

	}

	const onLoginSubmit = () => {

		let postData = loginDetails;
		postData.temp_token = temp_token
		console.log(postData, 'postData')
		setemailMessage('')
		setpasswordMessage('')
		let errors = [];

		if (postData.email == '') {
			errors.push('Email');
			setemailMessage('Email is required.!')
		}
		if (postData.password == '') {
			errors.push('Password');
			setpasswordMessage('Password is required.!')

		}

		if (errors.length > 0) {
			// toast.error(errors.toString() + ' is required.!');
			return false;
		}

		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(postData.email)) {
		} else {
			setemailMessage('Please enter the vaild email.!')

			//toast.error(' Please enter the vaild email .!');
			return false;
		}

		dispatch(doLogin({ postData }));
	};


	useEffect(() => {


		// // setNum(num)
		// if(productDatas && productDatas.data){
		// 	if(productDatas.data[0].price){
		// 		if(productDatas.data[0].price[0]){
		// 			setTotalCost((selectedPrice * num)+Number(toppins));
		// 		}
		// 	}

		// }
		// console.log(totalCost, 'totalCost');

		// setTotalCost(num * totalCost)
	}, [num])

	useEffect(() => {

		if (removeLoading === API_STATUS.FULFILLED) {
			toast.dismiss();
			toast.success("Item has been removed successfully.!");

			dispatch(
				cartData({})
			);

			setInterval(() => {
				window.location.reload()
			}, 1000);
		}

		if (removeLoading === API_STATUS.REJECTED) {
			toast.dismiss();
			setshowModal(true);
			toast.error(removeMessage);
		}

	}, [removeLoading])


	useEffect(() => {

		if (cartDatas) {
			let reward_Products = cartDatas.data && cartDatas.data.length && cartDatas.data.filter((item) => {
				if (item.reward == 1)
					return item
			})

			if (reward_Products && reward_Products.length) {
				setrewardProducts([...reward_Products])
			}

			let products = cartDatas.data && cartDatas.data.length && cartDatas.data.filter((item) => {
				if (item.reward == 0)
					return item
			})

			if (products && products.length) {
				setcartProducts([...products])
			}
		}
	}, [cartDatas])

	useEffect(() => {
		let taxdata = 0;
		if (generalDatas && generalDatas.length) {
			taxdata = parseFloat(Number(generalDatas[0].tax) / 100).toFixed(2);
			setsalestax(taxdata)
			setTimezone(generalDatas[0].timezone)
			console.log(taxdata, 'sales tax');
		}


		if (cartDatas && cartDatas.data) {
			console.log(cartDatas, 'cartdatas');
			console.log(generalDatas, 'cartdatas');
			// setSubTotal(cart)
			setsalestax(taxdata)
			let subcost = []
			let ordercost = []
			let rewardPoints = []
			let tax = salesTax;
			cartDatas.data.forEach(function (selecteditem) {
				//let subcost_array = selecteditem.item_price.split(',');
				//let final_subcost = subcost_array.reduce((a, b) => Number(a) + Number(b));
				if (selecteditem.reward == 1) {
					rewardPoints.push(selecteditem.total_cost);
				} else {
					subcost.push(selecteditem.total_cost);
					ordercost.push(selecteditem.total_cost);
				}

			});
			let subAmount;
			if (subcost.length == 1) {
				subAmount = subcost.reduce((a) => Number(a) + Number(0));
				setSubTotal(subAmount);
			} else if (subcost.length == 0) {
				subAmount = 0;
				setSubTotal(subAmount);

			} else {
				subAmount = subcost.reduce((a, b) => Number(a) + Number(b));
				setSubTotal(subAmount);
			}

			if (rewardPoints.length == 1) {
				setRewardTotal(rewardPoints.reduce((a) => Number(a) + Number(0)));
			} else if (rewardPoints.length == 0) {
				setRewardTotal(0);
			} else {
				setRewardTotal(rewardPoints.reduce((a, b) => Number(a) + Number(b)));
			}

			// console.log(taxAmount, 'aaaaa');
			let taxAmount = parseFloat(Number(subAmount) * Number(taxdata)).toFixed(2);
			let amount
			if (subcost.length == 1) {
				amount = ordercost.reduce((a) => Number(a) + Number(0));
				console.log(amount, 'aaaaa2');
				if (Number(amount) < 0) {
					taxAmount = 0
				}
				console.log(amount, 'aaa3');
				console.log(taxAmount, 'aaa4');
				setOrderTotal(Number(amount) + Number(taxAmount));
			} else if (subcost.length == 0) {
				setOrderTotal(0);
			} else {
				amount = ordercost.reduce((a, b) => Number(a) + Number(b));
				if (Number(amount) < 0) {
					taxAmount = 0
				}
				setOrderTotal(Number(amount) + Number(taxAmount));
			}

		} else {
			console.log('empty');
			// setTag([])
			// console.log(Tag, 'new Tags');
		}

	}, [cartDatas, generalDatas])

	const checkAuthentication = () => {
		if (userDetails?.id) {
			if (addCartTrue == true) {
				toast.error('You have Insufficent Reward Points!')
			} else {
				// setshowOrderMode(true)
				window.location.href = '/checkout'
			}
		} else {
			setshowLoginModal(true);
		}
	}
	const updateQuantity = (value, id, quantity) => {

		// if (num < 0) {
		// 	return false;
		// }
		if (value == 1) {
			setNum(num + value);
		} else {
			setNum(num - 1);
		}

		if (quantity > 1 && value == -1) {
			dispatch(
				updateQuantityData({
					id: id,
					quantity: value,
					// temp_token: localStorage.getItem('temporaryToken')
				})
			);
		}
		else if (value == 1) {
			dispatch(
				updateQuantityData({
					id: id,
					quantity: value,
					// temp_token: localStorage.getItem('temporaryToken')
				})
			);
		}
		// dispatch(
		// 	updateQuantityData({
		// 		id: id,
		// 		quantity: value,
		// temp_token: localStorage.getItem('temporaryToken')
		// 	})
		// );
	}

	const togglePassword = () => {
		if (PasswordType == 'text') {
			setPasswordType('password')
		} else {
			setPasswordType('text')

		}
	}

	useEffect(() => {

		if (loading === API_STATUS.FULFILLED) {
			setshowLoginModal(false);
			toast.dismiss();
			toast.success("Loggedin successfully.!");

			setInterval(() => {
				window.location.href = '/checkout'
			}, 1000);
		}

		if (loading === API_STATUS.REJECTED) {
			toast.dismiss();
			setshowLoginModal(true);
			toast.error(errorMessage);
		}


	}, [loading]);
	console.log(posDatas, 'posDatas');
	console.log(fromTime, 'form time');
	console.log(toTime, 'to time');
	console.log(generalDatas, 'generalDatas');
	return (
		<>
			<Header />

			<div className="page-content bg-white" style={(cartDatas && cartDatas.data && cartDatas.data?.length == 0) ? { backgroundImage: "url(" + img1 + ")", backgroundSize: "100%" } : {}}>
				{/* 				
				<div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{backgroundImage:"url(" + img + ")"}}>
					<div className="container">
						<div className="dlab-bnr-inr-entry">
							<h1 className="text-white">Cart</h1>
							
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'./'}>Home</Link></li>
									<li>Shop Cart</li>
								</ul>
							</div>
							
						</div>
					</div>
				</div> */}
				<div className="breadcrumb-row" style={{ backgroundColor: 'brown', paddingLeft: '20px' }}>
					<ul className="list-inline">
						<li><Link to={'./'}>Home</Link></li>
						<li><Link to={'#'}>Shop Cart</Link></li>
					</ul>
				</div>
				{/* { (loader) ? 
	
				<><Space
    direction="vertical"
    style={{
      width: '100%',
    }}
  ></Space>
    <Space>
      <Spin tip="Loading" size="small">
        <div className="content" />
      </Spin></Space></> : ''


}				 */}
				{(cartDatas && cartDatas.data && cartDatas.data?.length > 0) ? (

					<div className="section-full content-inner1" >

						<div className="container">

							<div className="row">

								<div className="col-lg-12">

									{(posDatas && posDatas.data) ? ((posDatas && posDatas.data[0].schedule == 0 || !fromTime || !toTime) ?

										<div className="tab-content product_list">
											<div id="web-design-1" className="tab-pane active">
												<p className="m-b0" style={{ color: 'red' }}> {posDatas.data[0].message} </p>
											</div>
										</div>
										: '') : ''
									}
									{addCartTrue ?
										<div className='row' style={{ paddingTop: '12px' }}>
											<div className='col-md-7'>
											</div>
											<div className='col-md-5'>
												<Badge.Ribbon text={<><i class="fa fa-dot-circle-o"></i>&nbsp;Reward Points</>} color="rgb(26 49 183 / 90%)">
													<Card size="small">
														<i class="fa fa-info-circle" aria-hidden="true"></i> &nbsp;<span style={{ fontSize:'1.3rem' }} >Available Reward Points :<b> {rewardPoints} pts</b></span>
													</Card>
												</Badge.Ribbon>
											</div>
										</div>
										: ''}
									{(rewardProducts && rewardProducts.length) ? rewardProducts.map((result, tindex) => (
										<div className='card p-5 my-4'>
											<div className='row'>
												<div className='col-2'>
													{result.reward == 1 ?
														<div className='reward-label'>
															<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
														</div>
														: (result.reward == 0 && (result.item_names) && result.default_custom == 0) ?
															<div className='customized-label'>
																<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
															</div>
															:
															// (result.reward == 0 && (result.item_names == "" || result.item_names == null)) ?
															<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
														// : ''
													}
												</div>
												<div className='col-5'>
													{
														(result.reward == 1) ? <a href={`/viewcartreward/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} ><span style={{ fontSize: '25px', fontWeight: '550' }}>{result.product.name}</span></a>
															:
															<a href={`/viewcart/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} ><span style={{ fontSize: '25px', fontWeight: '550' }}>{result.product.name}</span></a>
													}

													{/* <span className='ml-3'> Price: ${ result.total_cost }</span> */}

													<p>{result.product.description}</p>
													<p>{(result.item_names && result.default_custom == 0) ? <p><strong>Customized:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : (result.item_names && result.default_custom == 1) ? <p><strong>Default:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : ''}</p>
												</div>
												<div className='col-2'>
													<span ><strong>Size :</strong> {result.default_size_name}</span>
													<br /><span><strong>{(result.reward == 1) ? 'Points' : 'Price'}:</strong> {(result.reward == 1) ? result.product_price + ' pts' : result.product_price ? '$' + parseFloat(result.product_price).toFixed(2) : 0}</span>

												</div>
												<div className='col-3 text-center'>
													<div className="btn-group product-item-size" data-toggle="buttons">
														<button className="btn btn-outline-primary" disabled={result.quantity == 1 && quantityUpdated} type="button" onClick={() => {
															if (result.quantity > 1) {
																updateQuantity(-1, result.id, result.quantity)
															}
														}}><i className="ti-minus"></i>
														</button>
														<input readOnly style={{ 'height': '40px', paddingRight: '10px', width: '55px' }} type="text" className="form-control" value={result.quantity} />&nbsp;
														<button className="btn btn-outline-primary" type="button" onClick={() => {
															// setNum(num + 1 );
															updateQuantity(1, result.id)
														}}><i className="ti-plus"></i>
														</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

													</div>
													<h3><p className='ml-3' style={{ paddingRight: '40px', marginTop: '20px' }}> <strong>{(result.reward == 1) ? Number(result.total_cost) + ' pts' : '$' + parseFloat(result.total_cost).toFixed(2)}</strong></p></h3>
													{((result.total_cost > rewardPoints[0]) && result.reward == 1) ? <Alert message={"Insufficient Reward Points"} type="error" showIcon /> : ''}

													{
														(result.reward == 1) ? <><a className='btn btnhover  mt-3' href={`/viewcartreward/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} data-dismiss="alert" aria-label="close" title="View Product">Customize</a>&nbsp;&nbsp;</>
															:
															<><a className='btn btnhover  mt-3' href={`/viewcart/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} data-dismiss="alert" aria-label="close" title="View Product">Customize</a> &nbsp;&nbsp;</>
													}<button className='btn btn-danger remove-btn mt-3' href="#" data-dismiss="alert" aria-label="close" data-id={result.id} onClick={(e) => { e.preventDefault(); setshowModal(true); setcartID(result.id) }}>Remove</button>
												</div>
											</div>

										</div>
									)) : ''}
									{(cartProducts && cartProducts.length) ? cartProducts.map((result, tindex) => (
										<div className='card p-5 my-4'>
											<div className='row'>
												<div className='col-2'>
													{result.reward == 1 ?
														<div className='reward-label'>
															<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
														</div>
														: (result.reward == 0 && (result.item_names) && result.default_custom == 0) ?
															<div className='customized-label'>
																<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
															</div>
															:
															// (result.reward == 0 && (result.item_names == "" || result.item_names == null)) ?
															<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product.image} alt="" />
														// : ''
													}
												</div>
												<div className='col-5'>
													{
														(result.reward == 1) ? <a href={`/viewcartreward/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} ><span style={{ fontSize: '25px', fontWeight: '550' }}>{result.product.name}</span></a>
															:
															<a href={`/viewcart/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} ><span style={{ fontSize: '25px', fontWeight: '550' }}>{result.product.name}</span></a>
													}

													{/* <span className='ml-3'> Price: ${ result.total_cost }</span> */}

													<p>{result.product.description}</p>
													<p>{(result.item_names && result.default_custom == 0) ? <p><strong>Customized:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : (result.item_names.length > 0 && result.item_names && result.default_custom == 1) ? <p><strong>Default:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : ''}</p>
												</div>
												<div className='col-2'>
													<span ><strong>Size :</strong> {result.default_size_name}</span>
													<br /><span><strong>{(result.reward == 1) ? 'Points' : 'Price'}:</strong> {(result.reward == 1) ? result.product_price + ' pts' : result.product_price ? '$' + parseFloat(result.product_price).toFixed(2) : 0}</span>

												</div>
												<div className='col-3 text-center'>
													<div className="btn-group product-item-size" data-toggle="buttons">
														<button className="btn btn-outline-primary" disabled={result.quantity == 1 && quantityUpdated} type="button" onClick={() => {
															if (result.quantity > 1) {
																updateQuantity(-1, result.id, result.quantity)
															}
														}}><i className="ti-minus"></i>
														</button>
														<input readOnly style={{ 'height': '40px', paddingRight: '10px', width: '55px' }} type="text" className="form-control" value={result.quantity} />&nbsp;
														<button className="btn btn-outline-primary" type="button" onClick={() => {
															// setNum(num + 1 );
															updateQuantity(1, result.id)
														}}><i className="ti-plus"></i>
														</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

													</div>
													<h3><p className='ml-3' style={{ paddingRight: '40px', marginTop: '20px' }}> <strong>{(result.reward == 1) ? result.total_cost + ' pts' : '$' + parseFloat(result.total_cost).toFixed(2)}</strong></p></h3>
													{/* {((result.total_cost > rewardPoints) && result.reward == 1) ? <Alert message={"Insufficient Reward Points"} type="error" showIcon /> : ''} */}

													{
														(result.reward == 1) ? <><a className='btn btnhover  mt-3' href={`/viewcartreward/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} data-dismiss="alert" aria-label="close" title="View Product">Customize</a>&nbsp;&nbsp;</>
															:
															<><a className='btn btnhover  mt-3' href={`/viewcart/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} data-dismiss="alert" aria-label="close" title="View Product">Customize</a> &nbsp;&nbsp;</>
													}<a className='btn btn-danger remove-btn mt-3' href="#" data-dismiss="alert" aria-label="close" data-id={result.id} onClick={(e) => { e.preventDefault(); setshowModal(true); setcartID(result.id) }}>Remove</a>
												</div>
											</div>

										</div>
									)) : ''}
								</div>
							</div>
							<div className="row">
								<div className="col-lg-6 col-md-6 m-b30">

								</div>
								<div className="col-lg-6 col-md-6">
									<h3>Cart Subtotal</h3>
									<table className="table-bordered check-tbl">
										<tbody>
											<tr>
												<td>Order Subtotal</td>
												<td>${parseFloat(subTotal).toFixed(2)}</td>
											</tr>
											<tr>
												<td>Reward Points</td>
												<td>{rewardTotal} pts</td>
											</tr>
											<tr>
												<td>Sales Tax ({salesTax * 100}%) </td>
												<td>{(subTotal == 0) ? '$' + 0.00 : '$' + (subTotal * salesTax).toFixed(2)}</td>
											</tr>
											{/* <tr>
												<td>Coupon</td>
												<td>$0.00</td>
											</tr> */}
											<tr>
												<td>Total</td>
												<td>${parseFloat(orderTotal).toFixed(2)}</td>
											</tr>
										</tbody>
									</table>
									{/* setshowOrderMode(true)  */}
									{(posDatas && posDatas.data) ? ((posDatas && posDatas.data[0].schedule == 1 && fromTime && toTime) ?
										<div className="form-group">
											<button className="btn btnhover" type="button" onClick={(e) => { checkAuthentication() }}>Proceed to Checkout</button>

										</div>
										: '') : ''}
									{(addCartTrue) ? <Alert message={`You have Insufficient Reward points to Purchase Reward Products!`} type="warning" showIcon /> : ''}
									{/* {(addCartTrue) ? <Alert message={`You have only ${rewardPoints} pts`} type="warning" showIcon /> : ''} */}

								</div>
							</div>
						</div>

					</div>) : (<>

						<div className="row">
							<div className="col-lg-12">

								{(posDatas && posDatas.data) ? ((posDatas && posDatas.data[0].schedule == 0 || !fromTime || !toTime) ?

									<div className="tab-content product_list">
										<div id="web-design-1" className="tab-pane active">
											<p className="m-b0" style={{ color: 'red' }}> {posDatas.data[0].message} </p>
										</div>
									</div>
									: '') : ''
								}
							</div>
							<div className="col-lg-6 col-md-6 m-b30"></div>

							<div className="col-lg-6 col-md-6" style={{ marginTop: '30px', marginLeft: '500px' }}>
								<h3>Nothing In Cart</h3>
								<div className="form-group">
									<Link to={'/products'} className="btn btnhover" >Browse Products</Link>
								</div>
							</div>
						</div></>)
				}

				<Modal className='dialog-screen modal-dialog1' size="lg" show={showModal} onHide={onCloseCart}>
					<Modal.Header closeButton>
						<Modal.Title>Remove Cart</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Are you sure you want to remove the product from cart?
					</Modal.Body>
					<Modal.Footer>

						<Button variant="primary" onClick={onRemoveCart}>
							Remove
						</Button>
						<Button variant="secondary" onClick={onCloseCart}>
							Back
						</Button>
					</Modal.Footer>
				</Modal>


				<Modal dialogClassName="modal-90w" className='dialog-screen1 modal-dialog_pro' size="xl" show={showLoginModal} onHide={onCloseLoginCart}>
					<Modal.Header closeButton>
						<Modal.Title>Login</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<form id="login" onSubmit={(e) => submitHandler(e)} className="tab-pane active col-12 p-a0">
									<div className="form-group">
										<label >E-Mail *</label>
										<input
											className="form-control"
											id="signin-email"
											placeholder="Email"
											type="email"
											value={loginDetails.email}
											autoComplete="off"
											onChange={val => {
												setemailMessage('');
												setLoginDetails({ ...loginDetails, email: val.target.value });
											}}
										/>
										<span style={{ color: 'red' }}>{emailMessage}</span>
									</div>
									<div className="form-group">
										<label>Password *</label>
										{/* <input
									className="form-control"
									id="signin-password"
									placeholder="Password"
									type={PasswordType}
									value={loginDetails.password}
									autoComplete="off"
									onChange={val => {
										setpasswordMessage('')
										setLoginDetails({ ...loginDetails, password: val.target.value });
									}}
								/>
								<span style={{ position: 'absolute', right: '-24px', marginTop: '-34px' }}>
									{(PasswordType === 'password') ? <i onClick={togglePassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
										< i onClick={togglePassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
								</span>
								<span style={{ color: 'red' }}>{passwordMessage}</span> */}
										<div className='position-relative'>
											<input
												className="form-control"
												id="signin-password"
												placeholder="Password"
												type={PasswordType}
												value={loginDetails.password}
												autoComplete="off"
												onChange={val => {
													setpasswordMessage('')
													setLoginDetails({ ...loginDetails, password: val.target.value });
												}}
											/> <span style={{ position: 'absolute', top: '11px ', right: '15px' }}>
												{(PasswordType === 'password') ? <i onClick={togglePassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
													< i onClick={togglePassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
											</span>
											<span style={{ color: 'red' }}>{passwordMessage}</span>
										</div>
									</div>
									<Button className="btn btn-primary btn-block1" href="#" onClick={onLoginSubmit}
									>Login</Button>&nbsp;&nbsp;

									<Button variant="secondary" onClick={onCloseLoginCart}>
										Close
									</Button>
									<br />
									<br />
									<a to="#" data-toggle="tab" id="#forgot-password"
										className={classnames({ active: activeTab === '1' })}
										onClick={() => { toggle('2'); }}
									> <u id='hover_password'>Forgot Password</u></a> &nbsp;
									|&nbsp;
									<a href={'/register'}><u>Signup</u></a>&nbsp;&nbsp;&nbsp;
								</form>
							</TabPane>
							<TabPane tabId="2">	<form id="forgot-password"
								onSubmit={(e) => submitHandler(e)}
								className={activeTab === '2' ? 'tab-pane fade col-12 p-a0  show' : ' tab-pane fade col-12 p-a0 '}>
								<h4>Forgot Password ?</h4>
								<p>We will send you an email to reset your password. </p>
								<div className="form-group">
									<label>E-Mail *</label>
									<input
										className="form-control"
										id="signin-email"
										placeholder="Email"
										type="email"
										value={forgotDetails.email}
										autoComplete="off"
										onChange={val => {
											setForgotDetails({ ...forgotDetails, email: val.target.value });
										}}
									/>
								</div>
								<div className="text-left gray-btn">
									<button type="submit" className="btn btnhover" onClick={onForgotSubmit}>Submit</button>&nbsp;

									<Link
										className={classnames({ active: activeTab === '2' }) + ' btn  gray'}
										onClick={() => { toggle('1'); }}
										data-toggle="tab" to={"#"} >Back</Link>
								</div>
							</form>
							</TabPane>
						</TabContent>

					</Modal.Body>
					<Modal.Footer>
						{/* <button className="btn btn-primary btn-lg btn-block1" href="javascript:void(0);" onClick={onLoginSubmit}
						>Login</button>
						<Button variant="secondary" onClick={onCloseLoginCart}>
							Back
						</Button>
						<Link to={"#"} data-toggle="tab" id="#forgot-password" 
															className= {classnames({ active : activeTab === '1'}) + ' btn blue'} 
															onClick={() => { toggle('2'); }}
														><i className="fa fa-unlock-alt"></i> Forgot Password</Link>  */}

					</Modal.Footer>
					{/* <a href={'/register'} style={{ paddingLeft: '60px' }}>Create An Account</a> */}
				</Modal>

			</div>

			<Footer />
		</>
	)
	// }
}


export default Cart;