import React, { useState, useEffect } from 'react';
import './css/plugins.css';
import './css/style.css';
import './css/templete.css';
import './css/skin/skin-1.css';
import { Toaster } from 'react-hot-toast';
import { ThreeDots } from 'react-loader-spinner'
import Markup from './markup/Markup';
import { useSelector } from "react-redux";
import { ProductSelector } from '../src/store/reducer/product';
import loaderGif from '../src/images/gallery/burger.gif'
import { orderSelector } from '../src/store/reducer/order';

function App() {
  const { quantityUpdated } = useSelector(ProductSelector);
  const { paymentUpdated } = useSelector(orderSelector);
  const [loader, setloader] = useState(false)
  console.log(quantityUpdated, paymentUpdated, 'quantityUpdated---');

  useEffect(() => {
    if (quantityUpdated) {
      setloader(true)
    } else {
      setTimeout(() => {
        setloader(false)
      }, 500);
    }
  }, [quantityUpdated])

  useEffect(() => {
    if (paymentUpdated) {
      setloader(true)
    } else {
      setTimeout(() => {
        setloader(false)
      }, 300);
    }
  }, [paymentUpdated])

  return (
    <div className="App">
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#44b5b2"
        ariaLabel="three-dots-loading"
        wrapperClass='three-dot-spinner'
        visible={loader}
      />
      {/* {loader ? <img className='loader-gif' src={loaderGif}></img> : ''} */}
      <div className={loader ? 'blurry-bg' : ''}>
        <Markup />
      </div>
    </div>
  );
}

export default App;
