
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { RecentOrderList, orderSelector } from '../../store/reducer/order';
import moment from 'moment';
import {Form} from 'react-bootstrap';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { assestURL, secretKey } from '../../services/config';
import { Input, Space } from 'antd';
import { API_STATUS } from '../../utils/constants';
import NoOrderFound from '../../images/no_order1.png'; 
import { generalData, generalSelector } from "../../store/reducer/general";
import { toast } from 'react-hot-toast';
var img = require('./../../images/banner/bnr3.jpg');

function OrderList(){

	const dispatch = useDispatch();
	const {recentDatas, recentLoading } = useSelector(orderSelector);
	const {generalDatas } = useSelector(generalSelector);
    const [defaultPrice, setDefaultPrice] = useState([]);
	const favDatas = { data: []}
	const [timezone, setTimezone] = useState('America/Chicago');
    const RecentDispatch = () => {
        dispatch(
            RecentOrderList({})
        );
    };

	let alreadyLogged = localStorage.getItem('isAuthenticated');
	if (!alreadyLogged) {

		toast.dismiss();
		toast.success('User not authenticated.!');
		window.setTimeout(function () {
			window.location.href = '/'
		}, 550);
	}

	useEffect(() => {
        RecentDispatch()
    }, [])

	useEffect(() => {
        if(recentDatas){
            console.log(recentDatas, 'recentDatas');
		}
    }, [recentDatas])

	useEffect(() => {

		let taxdata = 0;
		if (generalDatas && generalDatas.length) {
			setTimezone(generalDatas[0].timezone)
		}

	}, [generalDatas])

	console.log(recentDatas, 'recentDatas');

		return(
			<>
			<Header />
			
			 <div className="page-content bg-white">
				<div className="breadcrumb-row" style={{ backgroundColor: '#c1272d', paddingLeft: '20px' }}>
					<ul className="list-inline">
						<li><Link to={`/`} style={{ color: 'white' }}>Home</Link></li>
						<li><Link to={`#`} style={{ color: 'white' }}>My Orders</Link></li>
					</ul>
				</div>
				<div className="section-full content-inner1">
					
					<div className="container">
						<div className="row">
						
		<div className="col-lg-12">
			<br></br>
		<p>Showing <strong>{ (recentDatas && (recentDatas.data && recentDatas.data.length) ) ? recentDatas.data.length : 0 } </strong>Orders</p>
			{recentDatas && recentDatas.data.map((result, tindex) => (
				<div className='card p-5 my-4 border-info' key={tindex} style={{ borderRadius: '2rem'}}>
					<div className='row'>
						<div className='col-4'>
							<span ><strong>Order ID :</strong> { result.id }</span><br></br>
							<span ><strong>Transaction ID :</strong> { (result.transactionid != 0 ) ? result.transactionid : 'Gift Card / Reward Purchase' }</span><br></br>
							<span ><strong>Ordered Date :</strong> { moment(result.created_at).tz(timezone).format('MM-DD-YYYY hh:mm A') }</span>
						</div>
						<div className='col-3'>
							<span ><strong>Item Count :</strong> { result.order_details.length  }</span><br></br>
							<span ><strong>Order Status :</strong> { (result.order_status == 1) ? 'Initiated' : 'Confirmed' }</span><br></br>
							<span ><strong>Order Mode :</strong> { (result.order_mode == 1) ? 'Drive-Thru' : ((result.order_mode == 2) ? 'Scheduled' : 'Pick Up') }</span><br></br>
						</div>
						<div className='col-2'>
						<span><strong>Total Amount :</strong><br></br> ${(result.order_total) ? parseFloat(result.order_total).toFixed(2) : 0.00 }</span><br></br>
					</div>
					<div className='col-3'>
							{/* <br/><span><strong>{(result.reward == 1) ? 'Points' : 'Price' }:</strong> {(result.reward == 1) ? result.product_price + ' pts' : result.product_price ? '$' + parseFloat(result.product_price).toFixed(2) : 0}</span> */}
							<a href={`/order/${EncryptDecrypt.encryptdata(result.id, secretKey)}`} className="btn btn-primary btn-lg btn-block" style={{ padding:'10px' }}>View Details</a>
						</div>
						
					</div>

				</div>
			))}

			{/* {favDatas && (favDatas.data && favDatas.data.length == 0) ? <div className="col-lg-6 col-md-6" style={{ marginTop: '30px', marginLeft: '500px' }}>
								<h3>Empty Favorites</h3>
								<div className="form-group">
									<Link to={'/products'} className="btn btnhover" >Browse Products</Link>
								</div>
							</div> : ''} */}
							<div className="row">
	
		
		<div className="col-lg-12">

			{recentDatas && (recentDatas.data && recentDatas.data.length == 0) ? <div className="col-lg-12 col-md-12" style={{ marginTop: '30px',textAlign:'-webkit-center'}}>
				<div className='col-md-6'>
					<div className="item-box shop-item style2">
						<div className="item-img">
							<img src={NoOrderFound} alt="" />
						</div>
					</div>
				</div>
				<br></br>
				<div className="form-group">
					<Link to={'/products'} className="btn btnhover" >Browse Products</Link>
				</div>
				</div> 
				: ''}
			</div>
		</div>
		</div>
		</div>
						
				   </div>
					
				</div>
				
			</div>
			
			<Footer  />
			</>
		)
} 


export default OrderList;