import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';

var img1 = require('./../../images/banner/bnr1.jpg');
var img2 = require('./../../images/background/bg5.jpg');

class Maintenance extends Component{
	render(){
		return(
			<>
			
			<header className="site-header header center mo-left header-style-2" style={{ position: 'sticky', top: '0' }}>

			<div className="sticky-header main-bar-wraper navbar-expand-lg">
				<div className="main-bar clearfix ">
					<div className="container clearfix">
						<button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
							<span></span>
							<span></span>
							<span></span>
						</button>

						<div className="header-nav navbar-collapse navbar myNavbar collapse justify-content-between" id="navbarNavDropdown">
							<div className="logo-header mostion">
								<Link to={'/'} className="dez-page"><img src={require("./../../images/horizon-full.svg")} alt="" /></Link>
							</div>
							<Link to={'/'} className="dez-page"><img src={require('./../../images/logos/app.png')} alt="" /></Link>
						</div>
					</div>
				</div>
			</div>
			
		</header>
			
			<div className="page-content bg-white">
				
				
				<div className="content-block">
					<div className="section-full content-inner-2 contact-form bg-white" style={{ backgroundImage:"url(" + img2 + ")" , backgroundSize:"100%" }} >
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="page-notfound text-center">
										<form method="post">
											<h3 style={{"fontSize":"75px"}}>Maintenance</h3>
											<h5 className="sub-title">We will be back soon <i className="fa fa-exclamation-triangle text-primary"></i></h5>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			
			<Footer />
			</>
		)
	}

}

export default Maintenance;