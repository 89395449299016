// const localHost = "localhost";

const localHostBaseEndpoint = (process.env.NODE_ENV === "development") ? process.env.REACT_APP_NODELOCALURL : process.env.REACT_APP_NODEPRODURL;

export const assestURL = (process.env.NODE_ENV === "development") ? process.env.REACT_APP_NODELOCALASSETURL : process.env.REACT_APP_NODEPRODASSETURL;

export const secretKey = process.env.REACT_APP_SECRETKEY





export const getBaseEndpointUrl = () => {

  const hostName = window.location.hostname;

  const hostUrl = getHostURL(hostName);

  return hostUrl;

};



function getHostURL(hostName) {

  // if (hostName.includes(localHost)) {

    return localHostBaseEndpoint;

  // }

}