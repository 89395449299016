import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  websiteSubCategoryProductList,websiteGetPosList } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "subcategoryproducts";

const initialState = {
    loading: "initial",
    errorMessage: null,
    // categoryData: null,
    subcategoryProductDatas: null,
    subcategoryProductCount: 0,
    posLoading: "initial",
    posMessage: null,
    posDatas: null
};

export const subcategoryProductData = createAsyncThunk(
    `${namespace}/subcategoryProductData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", subcategoryid=0}, { rejectWithValue, dispatch }) => {
        try {
            const data = await websiteSubCategoryProductList(query, page, limit, sortby, order,subcategoryid);
            console.log(data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const getPosData = createAsyncThunk(
    `${namespace}/getPosData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", }, { rejectWithValue, dispatch }) => {
        try {
		console.log('inside reduc posLoading');

            const data = await websiteGetPosList();
            console.log(data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const subcategoryProductSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [subcategoryProductData.fulfilled](state, { payload }) {
            console.log(payload, 'payload');
            state.loading = API_STATUS.FULFILLED;
            state.subcategoryProductCount = payload?.count;
            state.subcategoryProductDatas = payload?.data;
        },
        [subcategoryProductData.rejected](state, action, { payload }) {
            state.loading = API_STATUS.REJECTED;
            console.log(action?.payload, 'action?.payload?');

            if(action?.payload?.data?.auth==="deleted"){
                // alertmessage.sweetalert();
            }
            state.errorMessage = action?.payload?.data;
        },
        [getPosData.pending](state, { payload }) {
            state.posLoading = API_STATUS.PENDING;
        },
        [getPosData.fulfilled](state, { payload }) {
            state.posLoading = API_STATUS.FULFILLED;
            state.posDatas = payload?.data;
        },
        [getPosData.rejected](state, action, { payload }) {
            state.posLoading = API_STATUS.REJECTED;
            console.log(action?.payload, 'action?.payload?');
            state.posMessage = action?.payload?.data;
        },
    },
});

export const { clearData } = subcategoryProductSlice.actions;

export const subcategoryProductSelector = (state) => state.subcategoryproducts;

export default subcategoryProductSlice.reducer;
