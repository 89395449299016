import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UpdateOrderDetail, NewOrderPDF, ReOrderNew, viewOrderList, CreateNewOrder, CreateNewPayment, getScheduleTime, GetRecentOrderList } from "../../services/api";
import { secretKey } from "../../services/config";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "order";

const initialState = {
    paymentMessage: null,
    paymentDatas: null,
    paymentloading: "initial",
    createOrderloading: "initial",
    createOrderDatas: null,
    createOrderMessage: null,
    recentLoading: "initial",
    recentDatas: null,
    orderviewLoading: "initial",
    orderviewDatas: null,
    bookedScheduleTime: null,
    scheduleTimeloading: "initial",
    reorderDatas: null,
    reorderloading: "initial",
    paymentUpdated: false,
    orderUpdateDatas: null,
    orderUpdateloading: "initial",
};

export const getbookedScheduleTime = createAsyncThunk(
    `${namespace}/getbookedScheduleTime`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await getScheduleTime();
            console.log("scheduleTime redu--> ", result);
            return result;
        } catch (error) {
            console.log("scheduleTime error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const newPayment = createAsyncThunk(
    `${namespace}/newPayment`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("newPayment: ", postData);

            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await CreateNewPayment({ payload: postData });
            console.log("newPayment redu--> ", result);
            return result;
        } catch (error) {
            console.log("newPayment error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


export const CreateOrder = createAsyncThunk(
    `${namespace}/CreateOrder`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("CreateOrder: ", postData);

            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await CreateNewOrder({ payload: postData });
            console.log("newPayment redu--> ", result);
            return result;
        } catch (error) {
            console.log("newPayment error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const RecentOrderList = createAsyncThunk(
    `${namespace}/RecentOrderList`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("RecentOrderList: ", postData);

            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await GetRecentOrderList({ postData });
            console.log("newPayment redu--> ", result);
            return result;
        } catch (error) {
            console.log("newPayment error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const viewOrderData = createAsyncThunk(
    `${namespace}/viewOrderData`,
    async ({ order_id = '' }, { rejectWithValue, dispatch }) => {
        try {
            console.log("viewOrderData: ", order_id);

            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await viewOrderList(order_id);
            console.log("newPayment redu--> ", result);
            return result;
        } catch (error) {
            console.log("newPayment error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const ReOrderList = createAsyncThunk(
    `${namespace}/ReOrderList`,
    async ({ order_id = 0 }, { rejectWithValue, dispatch }) => {
        try {
            console.log("ReOrderList: ", order_id);

            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await ReOrderNew({
                payload: {
                    order_id: order_id
                }
            });
            console.log("newPayment redu--> ", result);
            return result;
        } catch (error) {
            console.log("newPayment error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const OrderPDF = createAsyncThunk(
    `${namespace}/OrderPDF`,
    async ({ order_id = '' }, { rejectWithValue, dispatch }) => {
        try {
            console.log("viewOrderData: ", order_id);

            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await NewOrderPDF(order_id);
            console.log("NewOrderPDF redu--> ", result);
            return result;
        } catch (error) {
            console.log("newPayment error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


export const UpdateOrder = createAsyncThunk(
    `${namespace}/UpdateOrder`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log("UpdateOrder: ", postData);

            // let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const result = await UpdateOrderDetail({ payload: postData });
            console.log("newPayment redu--> ", result);
            return result;
        } catch (error) {
            console.log("newPayment error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const orderSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {

        [getbookedScheduleTime.pending](state) {

            state.scheduleTimeloading = API_STATUS.PENDING;
        },

        [getbookedScheduleTime.fulfilled](state, action) {

            console.log(action, 'payload--');
            state.scheduleTimeloading = API_STATUS.FULFILLED;
            state.bookedScheduleTime = action?.payload?.data?.order
        },

        [getbookedScheduleTime.rejected](state) {

            state.scheduleTimeloading = API_STATUS.REJECTED;
        },
        [newPayment.pending](state) {
            console.log('order update');
            state.paymentUpdated = true

            state.paymentloading = API_STATUS.PENDING;
        },
        [newPayment.fulfilled](state, { payload }) {
            state.paymentloading = API_STATUS.FULFILLED;
            console.log('order update');
            state.paymentUpdated = false
            state.paymentDatas = payload?.data;
        },
        [newPayment.rejected](state, action) {
            // state.paymentUpdated = false
            state.paymentloading = API_STATUS.REJECTED;
            state.paymentMessage = action?.payload?.data?.message;
        },
        [CreateOrder.pending](state) {
            state.createOrderloading = API_STATUS.PENDING;
        },
        [CreateOrder.fulfilled](state, { payload }) {
            state.createOrderloading = API_STATUS.FULFILLED;
            state.createOrderDatas = payload?.data.orderId
        },
        [CreateOrder.rejected](state, action) {
            state.createOrderloading = API_STATUS.REJECTED;
            state.createOrderMessage = action?.payload?.data?.message;
        },
        [RecentOrderList.pending](state) {
            state.recentLoading = API_STATUS.PENDING;
        },
        [RecentOrderList.fulfilled](state, { payload }) {
            state.recentLoading = API_STATUS.FULFILLED;
            state.recentDatas = payload?.data
        },
        [RecentOrderList.rejected](state, action) {
            state.createOrderloading = API_STATUS.REJECTED;
            // state.createOrderMessage = action?.payload?.data?.message;
        },

        [viewOrderData.pending](state) {

            state.orderviewLoading = API_STATUS.PENDING;
        },
        [viewOrderData.fulfilled](state, action) {
            state.orderviewLoading = API_STATUS.FULFILLED;

            let payloaddatas = JSON.parse(EncryptDecrypt.apidecryptdata(action?.payload.data.datas, secretKey));
            state.orderviewDatas = payloaddatas;
        },
        [ReOrderList.pending](state) {
            state.reorderloading = API_STATUS.PENDING;
        },
        [ReOrderList.fulfilled](state, action) {
            state.reorderloading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(action?.payload.data.datas, secretKey));
            state.reorderDatas = action?.payload?.data;

        },

        [UpdateOrder.pending](state) {
            state.orderUpdateloading = API_STATUS.PENDING;
        },
        [UpdateOrder.fulfilled](state, { payload }) {
            state.orderUpdateloading = API_STATUS.FULFILLED;
            state.orderUpdateDatas = payload?.data
        },
        [UpdateOrder.rejected](state, action) {
            state.orderUpdateloading = API_STATUS.REJECTED;
            // state.createOrderMessage = action?.payload?.data?.message;
        },

    },
});

export const { clearData } = orderSlice.actions;

export const orderSelector = (state) => state.order;

export default orderSlice.reducer;
