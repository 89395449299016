import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  websiteClearItemCartData, websiteBannerList, websiteCategoryList, websiteClearCartData, websiteClearCartCategoryData, websiteClearCartProductData } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "category";

const initialState = {
    loading: "initial",
    clearLoading: "initial",
    clearcategoryLoading: "initial",
    cleaproductLoading: "initial",
    clearItemCartLoading: "initial",
    clearMessage: null,
    errorMessage: null,
    // categoryData: null,
    categoryDatas: {},
    categoryCount: 0,
    bannerLoading: 'initial',
    bannerDatas : null,

};

// export const allCategory = createAsyncThunk(
//     `${namespace}`,
//     async ({ postData }, { rejectWithValue, dispatch }) => {
//         try {
//             console.log(postData, 'dologin reducer');
//             //let payload = EncryptDecrypt.encryptdata(postData, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
//             const data = await categoryList(postData);
//             console.log("get category ", data);
//             return data;
//         } catch (error) {
//             console.log("category error", error);
//             return rejectWithValue(error.response);
//         }
//     }
// );

export const categoryData = createAsyncThunk(
    `${namespace}/category`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteCategoryList(query, page, limit, sortby, order);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const bannerData = createAsyncThunk(
    `${namespace}/banners`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteBannerList();
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const clearCartData = createAsyncThunk(
    `${namespace}/clearCartData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            console.log("interdata");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
          //  const clearcategory = await websiteClearCartCategoryData();
           // const clearsubcategory = await websiteClearCartProductData();
            const data = await websiteClearCartData();
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const clearProductData = createAsyncThunk(
    `${namespace}/clearProductData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            const data = await websiteClearCartProductData();
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const clearCategoryData = createAsyncThunk(
    `${namespace}/clearCategoryData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            const data = await websiteClearCartCategoryData();
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const clearInactiveItemCartData = createAsyncThunk(
    `${namespace}/clearInactiveItemCartData`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="" }, { rejectWithValue, dispatch }) => {
        try {
            const data = await websiteClearItemCartData();
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const categorySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [categoryData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log(payload, 'payload')
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, process.env.REACT_APP_SECRETKEY));
            state.categoryCount = payload?.count;
            state.categoryDatas = payload?.data;
            // state.categoryData = payload?.data;
            // localStorage.setItem('authToken',payload?.data?.accessToken)
            // localStorage.setItem('isAuthenticated',true)
            // localStorage.setItem('username',payload?.data?.username)
        },

        [bannerData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log(payload, 'payload')
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, process.env.REACT_APP_SECRETKEY));
            // state.bann = payload?.count;
            state.bannerDatas = payload?.data;
        },

        [clearCartData.fulfilled](state, { payload }) {
            state.clearLoading = API_STATUS.FULFILLED;
            // state.clearMessage = payload?.data;

        },

        [clearProductData.fulfilled](state, { payload }) {
            state.cleaproductLoading = API_STATUS.FULFILLED;
            // state.clearMessage = payload?.data;

        },

        [clearCategoryData.fulfilled](state, { payload }) {
            state.clearcategoryLoading = API_STATUS.FULFILLED;
            // state.clearMessage = payload?.data;

        },
        [clearInactiveItemCartData.fulfilled](state, { payload }) {
            state.clearItemCartLoading = API_STATUS.FULFILLED;
            // state.clearMessage = payload?.data;

        },
    },
});

export const { clearData } = categorySlice.actions;

export const categorySelector = (state) => state.category;

export default categorySlice.reducer;
