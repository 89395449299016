import React, {  useEffect} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import {Link, useParams} from 'react-router-dom';
// import { categoryData, categorySelector } from '../../store/reducer/category';
import { subcategoryData, subcategorySelector } from '../../store/reducer/subcategory';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from "../../services/config";
import EncryptDecrypt from './../../utils/encrypt&decrypt';

//Images	
var img1 = require('./../../images/background/bg5.jpg');



function Subcategory(){
	const {subcategoryDatas} = useSelector(subcategorySelector);
	let {id} = useParams();

	id = EncryptDecrypt.decryptdata(id, secretKey);
	const dispatch = useDispatch();
	const subcategoryDispatch = (id) => {

        dispatch(
            subcategoryData({
                // query: searchQuery,
                // page: page + 1,
                // limit: rowsPerPage,
                // sortby: sortBy,
                // order: order,
                categoryid: id
            })
        );
    };

	useEffect(() => {
        subcategoryDispatch(id)
    }, [])

	console.log(subcategoryDatas, 'subcategoryDatas');

	// render(){
		return(
			<>
				<Header />
			
				<div className="page-content bg-white">
					<div className="content-block">
						{/* <Slider /> */}
						{
					(subcategoryDatas && subcategoryDatas.data) ? 
							<div className="breadcrumb-row"  style={{ backgroundColor:'#c1272d', paddingLeft:'20px' }}>
								<ul className="list-inline">
									<li><Link to={'/'} onClick={() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth", }); }} style={{ color:'white' }}>Home</Link></li>
									{/* <li>{ subcategoryDatas.data ? subcategoryDatas.data.name : '-' }</li> */}

									<li>{ (subcategoryDatas && subcategoryDatas.data) ? <Link to={'/'} style={{ color:'white' }}>{subcategoryDatas.data.name}</Link> : ''}</li>

								</ul>
							</div>
							
					: 
							<div className="breadcrumb-row">
								<ul className="list-inline">
									<li><Link to={'/'} onClick={() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth", }); } } style={{ color:'white' }}>Home</Link></li>
									<li>{ subcategoryDatas.data ? subcategoryDatas.data.name : '-' }</li>
								</ul>
				</div> }

				<div className="section-full content-inner-3" style={{backgroundImage:"url(" + img1 + ")", top:'15px'}}>
							<div className="container">
								<div className="row service-area1">
									
									{ (subcategoryDatas && subcategoryDatas.subcategories) ?  subcategoryDatas.subcategories.map((result, index) => {

										return  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-center-block">
											<a href={(`/subcategories/products/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)}>
										<div className="icon-bx-wraper text-center service-box1" style={{backgroundImage: "url('" + assestURL + result.image + "')"}}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">{ result.name } </h2>
												<p>{ result.description }</p>
												<div className="dlab-separator style1 bg-primary"></div>
												{/* <Link to={(`/product-details`)} className="btn btnhover">More details <i className="fa fa-angle-double-right m-l5"></i></Link> */}
												<Link to={(`/subcategories/products/${EncryptDecrypt.encryptdata(result.id, secretKey)}`)} className="btn btnhover" onClick={() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth", }); }}>More details <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div></a>
									</div>
									})
									: <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-center-block">No Image </div>}

								</div>

							</div>
						</div>		
					</div>		
				</div>
				<Footer />
			</>	
		)
	// }
} 

export default Subcategory;