import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { custommenuList,custommenuListById } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import { secretKey } from "../../services/config";
const namespace = "custommenu";

const initialState = {
    loading: "initial",
    errorMessage: null,
    custommenuDatas: null,
    custommenuCount: 0,
    // custommenuDatasbyID: null
};

export const custommenuData = createAsyncThunk(
    `${namespace}/custommenuData`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, "menu 2");
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await custommenuList(postData);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
// export const custommenuDatabyID = createAsyncThunk(
//     `${namespace}/custommenuDatabyID`,
//     async ({ postData }, { rejectWithValue, dispatch }) => {
//         try {
//             console.log("interdata");
//             let payload = EncryptDecrypt.encryptdata(postData, secretKey)
//             //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
//             const data = await custommenuListById({ payload: payload });
//             console.log("getCount--> ", data);
//             return data;
//         } catch (error) {
//             console.log("getCount error--->", error);
//             return rejectWithValue(error.response);
//         }
//     }
// );



const custommenuSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        clearCMLoadingDatas: (state) => {
            state.loading = "initial";
        },
       
        clearErrormsg: (state) => {
            state.errorMessage = null;
        }
    },
    extraReducers: {
        [custommenuData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [custommenuData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
            // console.log( payloaddatas?.data,"  payloaddatas?.data")
            state.custommenuCount = payload.data?.count;
            state.custommenuDatas = payload.data?.data;
        },
        [custommenuData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
           
            
            state.errorMessage = action?.payload?.data;
        },
        // [custommenuDatabyID.pending](state) {
        //     state.loading = API_STATUS.PENDING;
        // },
        // [custommenuDatabyID.fulfilled](state, { payload }) {
        //     state.loading = API_STATUS.FULFILLED;
        //     console.log(payload,"actualpayload")
        //     // state.custommenuCount = payload?.data?.count;
        //     // state.custommenuDatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, secretKey));
        //     let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data, secretKey));
        //     console.log(payloaddatas?.data,"payload")
        //     state.custommenuDatasbyID = payloaddatas?.data;
        // },
        // [custommenuDatabyID.rejected](state, action) {
        //     state.loading = API_STATUS.REJECTED;
           
            
        //     state.errorMessage = action?.payload?.data;
        // },
    },
});

export const { clearData, clearLoadingDatas, clearErrormsg } = custommenuSlice.actions;

export const custommenuSelector = (state) => state.custommenu;

export default custommenuSlice.reducer;
