import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addToProductFavourite, getProfileDetails, ProfileUpdateDetails, PasswordUpdateDetails } from "../../services/api";
import { secretKey } from "../../services/config";
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "profile";

const initialState = {
    errorMessage: null,
    profileDatas: null,
    profileloading: "initial",
    updateLoading: 'initial',
    profileerrorMessage: null,
    success: '',
    passwordloading: "initial",
    passwordMessage: null,
    addloading: "initial",
    addMessage: null
};

export const profileData = createAsyncThunk(
    `${namespace}/profile`,
    async ({ user_id = '' }, { rejectWithValue, dispatch }) => {
        try {

            const result = await getProfileDetails(user_id);
            console.log("get profile details : ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const profileUpdateData = createAsyncThunk(
    `${namespace}/profileUpdate`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            let payload = EncryptDecrypt.apiencryptdata(postData, secretKey)
            console.log(payload, 'decryptpayload==');
            const result = await ProfileUpdateDetails({ payload: payload });
            console.log("profiledata redu--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const updatePassword = createAsyncThunk(
    `${namespace}/passwordUpdate`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            let payload = EncryptDecrypt.apiencryptdata(postData, secretKey)

            const result = await PasswordUpdateDetails({ payload: payload });
            console.log("PasswordUpdateDetails redu--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const addToFavourite = createAsyncThunk(
    `${namespace}/addToFavourite`,
    async ({ product_id = '', status = '' }, { rejectWithValue, dispatch }) => {
        try {
            console.log("postData redux--> ", product_id, status);
            const result = await addToProductFavourite({ payload: { product_id: product_id, status: status } });
            console.log("addToProductFavourite redux--> ", result);
            return result;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const profileSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        // [profileData.pending](state, {payload}) {
        //     state.profileloading = API_STATUS.PENDING;
        //     // state.profileDatas = payload?.datas;
        // },
        [profileData.fulfilled](state, { payload }) {
            state.profileloading = API_STATUS.FULFILLED;

            console.log(payload, 'payload fullfilled');
            state.profileDatas = payload?.data.datas;
        },
        [profileData.rejected](state, action, { payload }) {
            state.profileloading = API_STATUS.REJECTED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.datas, secretKey));
            console.log(payload, 'payload erro');
            state.errorMessage = action?.payload?.datas;
        },
        [profileUpdateData.pending](state) {
            console.log('pending update');

            state.updateLoading = API_STATUS.PENDING;
        },
        [profileUpdateData.fulfilled](state, { payload }) {
            state.updateLoading = API_STATUS.FULFILLED;
            state.success = payload?.message;
            state.profileerrorMessage = payload?.message;

            // console.log(payload, 'update fullfilled');
            // state.profileDatas = payload?.message;
        },
        [profileUpdateData.rejected](state, action) {
            state.updateLoading = API_STATUS.REJECTED;
            // let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.datas, secretKey));
            // console.log( action?.payload?.data?.errors[0].message, 'update rejected');
            state.profileerrorMessage = action?.payload?.data.message;
        },
        [updatePassword.pending](state) {
            console.log('pending update');

            state.passwordloading = API_STATUS.PENDING;
        },
        [updatePassword.fulfilled](state, { payload }) {
            state.passwordloading = API_STATUS.FULFILLED;
            console.log('pending update');

            state.passwordMessage = payload?.data;
        },
        [updatePassword.rejected](state, action) {
            state.passwordloading = API_STATUS.REJECTED;
            state.passwordMessage = action?.payload?.data?.errors[0].message;
        },
        [addToFavourite.pending](state) {
            console.log('pending update');

            state.addloading = API_STATUS.PENDING;
        },
        [addToFavourite.fulfilled](state, { payload }) {
            state.addloading = API_STATUS.FULFILLED;
            console.log('fulfilled update');

            state.addMessage = payload?.message;
        },
    },
});

export const { clearData } = profileSlice.actions;

export const profileSelector = (state) => state.profile;

export default profileSlice.reducer;
