import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { RecentOrderList, orderSelector } from '../../store/reducer/order';
import moment from 'moment';
import EncryptDecrypt from '../../utils/encrypt&decrypt';
import { assestURL, secretKey } from '../../services/config';
import { Input, Space } from 'antd';
import { API_STATUS } from '../../utils/constants';
import { toast } from 'react-hot-toast';
import NoOrderFound from '../../images/no_order1.png'; 

function RecentOrder(){

	const dispatch = useDispatch();
	const {recentDatas, recentLoading } = useSelector(orderSelector);
    const [defaultPrice, setDefaultPrice] = useState([]);
	const favDatas = { data: []}
    const RecentDispatch = () => {
        dispatch(
            RecentOrderList({})
        );
    };

	useEffect(() => {
        RecentDispatch()
    }, [])

	useEffect(() => {
        if(recentDatas){
            console.log(recentDatas, 'recentDatas');
		}
    }, [recentDatas])

	console.log(recentDatas, 'recentDatas');
		return(
			<>
			<Header />
			
			<div className="section-full content-inner1" >

<div className="container">

	<div className="row">
	
		<div className='col-6'>
		<h2>My Orders</h2>
        </div>
		<div className="col-lg-12">
		<p>Showing <strong>{ (recentDatas && (recentDatas.data && recentDatas.data.length) ) ? recentDatas.data.length : 0 } </strong>Orders</p>
			{recentDatas && recentDatas.data.map((result, tindex) => (
				<div className='card p-5 my-4'>
					<div className='row'>
						<div className='col-2'>
							
						</div>
						<div className='col-5'>
						
						</div>
						<div className='col-2'>
						
						</div>
						<div className='col-3 text-center'>
						
						
						
						</div>
					</div>

				</div>
			))}

			{recentDatas && (recentDatas.data && recentDatas.data.length == 0) ? <div className="col-lg-12 col-md-12" style={{ marginTop: '30px',textAlign:'-webkit-center'}}>
								<div className='col-md-6'>
                                <div className="item-box shop-item style2">
                                        <div className="item-img">
                                            {/* <img src="/home/manojkumar/Documents/Dipmenu/no_order1.png"/> */}
                                            <img src={NoOrderFound} alt="" />
                                        </div>
                                        
                                    </div>
                                </div>

                                {/* <h3>No Order Found</h3> */}
								{/* <h4>Looks like you haven't made your choice yet.</h4> */}
								<div className="form-group">
									<Link to={'/products'} className="btn btnhover" >Browse Products</Link>
								</div>
							</div> : ''}
		</div>
	</div>
</div>

</div>
			
			<Footer  />
			</>
		)
	}


export default RecentOrder;