import React, { useEffect, useState, useRef } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { profileData, profileUpdateData, profileSelector } from "../../store/reducer/profile";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import SimpleReactValidator from 'simple-react-validator';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import profileAvatar from "../../images/gallery/profile.jpg"
import Profilebanner from "../../images/background/profile_banner.png"
import { assestURL, secretKey } from '../../services/config';

const Profile = ({ history }) => {
	const formValidator = useRef(new SimpleReactValidator());
	const dispatch = useDispatch();
	let userDetails = JSON.parse(localStorage.getItem('userdata'));

	if (!userDetails) {
		// dispatch(logOut());
		window.setTimeout(function () {
			window.location.href = '/'
		}, 1000);
	}
	const [loginUser] = useState(userDetails.id);
	const [formDetails, setformDetails] = useState(
		{ id: loginUser, first_name: "", last_name: "", email: "", role_id: 2, mobile: '', location: '', reward_points: 0, profile_imageurl: '', imageName: '', imageUpload: false });
	const { profileDatas, updateLoading, profileerrorMessage } = useSelector(profileSelector);
	const [AddFormSubmit, setAddFormSubmit] = useState(false)
	const [fileUpload, setfileUpload] = useState('');
	const [fileName, setFileName] = useState("");

	const imageOnChange = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		const file = e.target.files[0];

		reader.onabort = () => console.log('file reading was aborted')
		reader.onerror = () => console.log('file reading has failed')
		reader.onloadend = () => {
			const binaryStr = reader.result
			setformDetails({
				...formDetails,
				// profile_imageurl: binaryStr,
				imageName: file.name
			})
			setfileUpload(binaryStr)
			setFileName(file.name)
		}
		reader.readAsDataURL(file);
	}

	const onProfileUpdate = (e) => {
		e.preventDefault();
		setAddFormSubmit(true);

		setFileName('')

		let postData = formDetails;

		if (fileUpload) {
			postData.profile_imageurl = fileUpload;
			postData.imageUpload = true
		}

		if (formValidator.current.allValid()) {
			dispatch(profileUpdateData({ postData }));
			toast.success('Profile updated successfully.!');
		}
	};

	useEffect(() => {
		formValidator.current.showMessages()
	}, [])


	useEffect(() => {
		console.log(updateLoading, 'updateLoading loading');
		if (updateLoading === API_STATUS.FULFILLED) {
			toast.dismiss();
			//  if(profileerrorMessage){
			toast.success('Profile updated successfully.!');
			// 
		}

		if (updateLoading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error(profileerrorMessage);
		}
		if (updateLoading === API_STATUS.PENDING) {
			toast.dismiss();
		}

	}, [updateLoading]);

	useEffect(() => {
		if (profileDatas) {
			setformDetails({
				...formDetails,
				first_name: profileDatas.first_name,
				last_name: profileDatas.last_name,
				email: profileDatas.email,
				mobile: profileDatas.mobile,
				location: profileDatas.location,
				profile_imageurl: profileDatas.profile_imageurl,
				// imageName: profileDatas.imageName,
				reward_points: profileDatas.reward_points
			})

		}

	}, [profileDatas]);


	const getProfileDispatch = (loginUser) => {

		dispatch(
			profileData({ user_id: loginUser })
		);

	};

	useEffect(() => {
		getProfileDispatch(loginUser)
		// console.log(profileDatas, 'profileDatas new');
	}, [])

	return (
		<>
			<Header />
			<div className="page-content bg-white">
				<div className="section-full mt-4 shop-account">
					<div className="container">
						<h3 className="m-b5">Profile Details </h3>
						<div className="row">
							<div className="col-lg-12">
								<div className="p-a30 border-1 m-auto radius-sm">
									<div className="tab-content">
										<form id="login" className="tab-pane active">
											<div style={{ backgroundImage: `url(${Profilebanner})`, backgroundSize: 'cover' }} className="form-group text-center" >
												<div>
													<label htmlFor="photo-upload ml-0" className="custom-file-upload fas">
														<div className='position-relative'>
															<div className="img-wrap" >
																<input id='profile-input' type='file' accept='image/*' onChange={imageOnChange}></input>
																<label className='position-absolute profile-edit' htmlFor='profile-input'><span className='profile-edit-btn rounded-circle p-1 bg-secondary '><i className='fa fa-edit text-white'></i></span></label>
																{fileUpload ? <img for="photo-upload" src={fileUpload} alt="" /> : formDetails.profile_imageurl && fileUpload == '' ? <img for="photo-upload" src={assestURL + formDetails.profile_imageurl} alt="" /> : fileUpload == '' && formDetails.profile_imageurl == '' ? < img for="photo-upload" src={profileAvatar} alt="" /> : ''}
															</div>
															<label style={{ paddingTop: '15px' }}>Edit Profile Photo</label>
														</div>
													</label>
												</div>
											</div>
											<div className='row'>
												<div className="form-group col-6" >
													<label>First Name <span style={{ color: 'red' }}>*</span></label>
													{/* <input required="" className="form-control" placeholder="First Name" type="text" /> */}
													<input
														className="form-control"
														name='first_name'
														placeholder="First Name"
														type="text"
														value={formDetails.first_name}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, first_name: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'first_name',
														formDetails.first_name,
														'required|alpha_space',
														{
															className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter First Name',
																alpha_space: 'Please Enter Valid Input',
															}
														}
													)}
												</div>
												<div className="form-group col-6">
													<label>Last Name <span style={{ color: 'red' }}>*</span></label>
													{/* <input required="" className="form-control" placeholder="Last Name" type="text" /> */}
													<input
														className="form-control"
														placeholder="Last Name"
														name='last_name'
														type="text"
														value={formDetails.last_name}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, last_name: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'last_name',
														formDetails.last_name,
														'required|alpha_space',
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Last Name',
																alpha_space: 'Please Enter Valid Input',
															}
														}
													)}
												</div>
												<div className="form-group col-6">
													<label>Mobile <span style={{ color: 'red' }}>*</span></label>
													{/* <input required="" className="form-control" placeholder="Last Name" type="text" /> */}
													<input
														className="form-control"
														name='mobile'
														placeholder="Mobile"
														type="text"
														value={formDetails.mobile}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, mobile: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'mobile',
														formDetails.mobile,
														'required|phone',
														{
															className: `invalid_feedback 
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Mobile Number',
																phone: 'Please Enter Valid Mobile Number',
															}
														}
													)}
												</div>
												<div className="form-group col-6">
													<label>Location <span style={{ color: 'red' }}>*</span></label>
													{/* <input required="" className="form-control" placeholder="Last Name" type="text" /> */}
													<input
														className="form-control"
														name='location'
														placeholder="Location"
														type="text"
														value={formDetails.location}
														autoComplete="off"
														onChange={val => {
															setformDetails({ ...formDetails, location: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'location',
														formDetails.location,
														// 'required|alpha_num_space',
														['required',
															{ regex: /^[a-zA-Z0-9\s,/:'-]*$/ }],
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter Location',
																regex: 'Please Enter Valid Input',
															}
														}
													)}
												</div>
												<div className="form-group col-6">
													<label>E-Mail <span style={{ color: 'red' }}>*</span></label>
													<input
														className="form-control"
														name='email'
														placeholder="Email"
														type="email"
														value={formDetails.email}
														autoComplete="off"
														readOnly={true}
														onChange={val => {
															setformDetails({ ...formDetails, email: val.target.value });
														}}
													/>
													{formValidator.current.message(
														'email',
														formDetails.email,
														'required|email',
														{
															className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
															messages: {
																required: 'Please Enter E-mail',
																email: 'Please Enter Valid E-mail',
															}
														}
													)}
												</div>
												<div className="form-group col-6">
													<label>Reward Points</label>
													<input
														className="form-control"
														name='rewards'
														placeholder="Reward Points"
														type="text"
														value={formDetails.reward_points}
														autoComplete="off"
														readOnly={true}
													/>
												</div>
												<div className='col-6'>
													<a className="btn btn-success pull-right w-50"
														onClick={onProfileUpdate}
													>Update</a>
												</div>
												<div className='col-6'>
													<a href='/changepassword' style={{ color: 'black' }}
														className='btn btn-info  text-center w-50' >Change password</a>
												</div>
											</div>

											{/* <button  type="submit" className="btn btnhover">CREATE</button> */}

										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div >
			<Footer />
		</>

	)
}

export default Profile;
