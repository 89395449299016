import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { aboutData, ContactSelector } from '../../store/reducer/contactus';
import { assestURL } from '../../services/config';

var img3 = require('./../../images/background/bg5.jpg');


function About (){
	window.scrollTo(0, 0)
	const dispatch = useDispatch();
	const {aboutDatas} = useSelector(ContactSelector);

	const aboutUsDispatch = () => {
        dispatch(
            aboutData({})
        );
    };

	useEffect(() => {
        aboutUsDispatch()
    }, [])

	console.log(aboutDatas, 'aboutDatas');

		return(
			<>
			<Header />
		
			<div className="page-content bg-white">

				
				<div className="content-block">
					
					<div className="section-full bg-white content-inner1" style={{backgroundImage:"url(" + img3 + ")", backgroundSize:"100%" , marginTop:'30px'}}>
					
					<div className="tab-content product_list">
						<div id="web-design-1" className="tab-pane active">
							<p className="m-b0" style={{ fontSize:'20px' }}> Our History</p> 	
						</div>
					</div>
					
						<div className="container" style={{ marginTop:'10px' }}>
							{
								(aboutDatas && aboutDatas.data) ? aboutDatas.data.map((result) => {

							return <><div className="row">
								<div className="col-lg-12">
									{/* <div className="section-head"> */}
									
										<h3>{result.name}</h3>
									{/* </div> */}
								</div>
							{/* </div> */}

							{/* <div className="row"> */}
								<div className="col-lg-4">
									<div className="section-head">
										<div className="icon-bx">
											<img src={ assestURL + result.image } alt="" />
										</div>
									</div>
								</div>
								<div className="col-lg-8">
									<div className="section-head">
										
										<p dangerouslySetInnerHTML={{ __html: result.content }}></p>
									</div>
								</div>
							</div></>
							
						}) : ''
					}
							
						</div>
					</div>
				
					
				</div>
				
			</div>	
			
			<Footer />
			</>
		)
	}

export default About;