import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { useDispatch, useSelector } from "react-redux";
import { assestURL, secretKey } from '../../services/config';
import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/encrypt&decrypt';
import 'moment-timezone';
import { ReOrderList, RecentOrderList, orderSelector } from "../../store/reducer/order";
import { profileData, profileUpdateData, profileSelector } from "../../store/reducer/profile";
import NoOrderFound from '../../images/no_order1.png';
import { Button, Modal } from "react-bootstrap";
import { toast } from 'react-hot-toast';

var img1 = require('./../../images/background/bg5.jpg');

function OrderView() {
	// render(){
	// window.scrollTo(0, 0)
	let { id } = useParams();
	id = EncryptDecrypt.decryptdata(id, secretKey);

	const dispatch = useDispatch();
	const [orderTotal, setOrderTotal] = useState(0);
	const [timezone, setTimezone] = useState('America/Chicago');
	const { recentDatas, reorderDatas, reorderloading } = useSelector(orderSelector);
	const [OrderDetails, setOrderDetails] = useState('');
	const [inputData, setinputData] = useState({ id: '' });
	const [showModal, setshowModal] = useState(false);
	const [ShowImage, setShowImage] = useState(0);
	const [ShowCustomItemPrice, setShowCustomItemPrice] = useState([]);
	const [ShowCustomItemName, setShowCustomItemName] = useState([]);

	let alreadyLogged = localStorage.getItem('isAuthenticated');
	if (!alreadyLogged) {
		// alert(window.history.back());
		toast.dismiss();
		toast.success('User not authenticated.!');
		// window.location.href = host
		window.setTimeout(function () {
			window.location.href = '/'
		}, 550);
	}
	const OrderViewDispatch = (id) => {
		const postData = inputData
		postData.id = id
		console.log(postData, 'postdata');
		dispatch(
			RecentOrderList({ postData })
		);

	};

	useEffect(() => {
		OrderViewDispatch(id)
	}, [])

	const PopupModal = () => {
		setshowModal(true)
	}

	const onCloseCart = () => {
		setshowModal(false);
	}

	const ReorderConfirmation = () => {

		dispatch(
			ReOrderList({
				order_id: id
			})
		);
	}


	useEffect(() => {

		if (recentDatas) {
			let custom_price_array = [];
			let custom_itemname_array = [];

			let customitem = recentDatas?.data[0].order_details.map((NewOrderDetails, NewKey) => {
				custom_itemname_array[NewKey] = ''
				if (recentDatas?.data[0].order_item_details.length > 0) {
					let price_array = recentDatas?.data[0].order_item_details.map((itemdetails, itemkey) => {
						if (NewOrderDetails.product_id == itemdetails.product_id && NewOrderDetails.id == itemdetails.order_detail_id) {
							if (custom_itemname_array[NewKey] == '') {
								custom_itemname_array[NewKey] = itemdetails.item_name
							} else {
								if (custom_itemname_array[NewKey] != '')
									custom_itemname_array[NewKey] = custom_itemname_array[NewKey] + "," + itemdetails.item_name
							}
							return Number(itemdetails.item_cost)
						} else {
							return 0
						}
					})

					custom_price_array[NewKey] = 0;
					if (price_array.length == 1) {
						custom_price_array[NewKey] = price_array.reduce((a, b) => Number(a) + Number(0));
					} else if (price_array.length > 1) {
						custom_price_array[NewKey] = price_array.reduce((a, b) => Number(a) + Number(b));
					}

				} else {
					custom_price_array[NewKey] = 0
				}
			})

			setShowCustomItemPrice(custom_price_array)
			setShowCustomItemName(custom_itemname_array)
		}
	}, [recentDatas])

	useEffect(() => {

		if (reorderloading === API_STATUS.FULFILLED) {

			if (reorderDatas.status == false) {
				toast.dismiss();
				toast.error(reorderDatas.message);
				setshowModal(false);
			} else {
				toast.dismiss();
				toast.success(reorderDatas.message);
				setshowModal(false);
				window.setTimeout(function () {
					window.location.href = '/cartlist'
				}, 3000);
			}
		} else if (reorderloading === API_STATUS.PENDING) {
			console.log('erorr')

		}

		if (reorderloading === API_STATUS.REJECTED) {
			toast.dismiss();
			toast.error('Something went wrong.!');
			setshowModal(false);
		}
	}, [reorderloading]);

	console.log(recentDatas, 'recentDatass');
	return (
		<>
			<Header />

			<div className="page-content bg-white" >

				<div className="breadcrumb-row" style={{ backgroundColor: 'brown', paddingLeft: '20px' }}>
					<ul className="list-inline">
						<li><Link to={'./'} style={{ color: 'white' }}>Home</Link></li>
						<li><Link to={'/orderlist'} style={{ color: 'white' }}>My Orders</Link></li>
						<li><Link to={'#'} style={{ color: 'white' }}>View</Link></li>
					</ul>
				</div>

				<div className="section-full content-inner1" >

					<div className="container">

						<div className="row">

							<div className="col-lg-12">
								<br></br>
								<h3>Order ID : # {(recentDatas && recentDatas?.data && recentDatas?.data.length) ? recentDatas?.data[0].id : ''}
									<Link to={'#'} className="btn btn-primary pull-right" onClick={e => { setshowModal(true) }}>Reorder</Link></h3>
								{(recentDatas && recentDatas?.data && recentDatas?.data?.length) ? recentDatas?.data[0].order_details.map((result, index) => {
									return <div className='card p-2 my-4'>
										<div className='row' style={{ padding: '2rem' }}>
											<div className='col-md-2'>
												{result.reward == 1 ?
													<div className='reward-label'>
														<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product_image} alt="" />
													</div>
													: (result.reward == 0 && (recentDatas?.data[0].order_item_details && recentDatas?.data[0].order_item_details.length) && result.default_custom == 0) ?
														<div className='customized-label'>
															<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product_image} alt="" />
														</div>
														:
														// (result.reward == 0 && (result.item_names == "" || result.item_names == null)) ?
														<img style={{ width: '165px', height: '165px', objectFit: 'cover' }} className="product-item-img " src={assestURL + result.product_image} alt="" />
													// : ''
												}
											</div>
											<div className='col-md-4'>
												{
													(result.reward == 1) ? <a href={`/rewardproductview/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}`} ><span style={{ fontSize: '25px', fontWeight: '550' }}>{result.product_name}</span></a>
														:
														<a href={`/productview/${EncryptDecrypt.encryptdata(result.product_id, secretKey)}`} ><span style={{ fontSize: '25px', fontWeight: '550' }}>{result.product_name}</span></a>
												}

												{/* <span className='ml-3'> Price: ${ result.total_cost }</span> */}

												<p>{result.product_description}</p>
												<p>{ShowCustomItemName[index]}</p>
												<p>{(result.item_names && result.default_custom == 0) ? <p><strong>Customized:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : (result.item_names && result.default_custom == 1) ? <p><strong>Default:</strong> {result.item_names.replaceAll(',', ',  ')}</p> : ''}</p>
											</div>
											<div className='col-md-2'>
												<span ><strong>Quantity :</strong> {result.quantity}</span>
											</div>
											<div className='col-md-2'>
												<span ><strong>Size :</strong> {result.itemsize_name}</span>
											</div>
											<div className='col-md-2'>
												<span><strong>{(result.reward == 1) ? 'Total Points' : 'Total  Price'}:</strong> {(result.reward == 1) ? parseInt(Number(ShowCustomItemPrice[index]) + Number(result.product_price)) + ' pts' : result.product_price ? '$' + parseFloat(Number(ShowCustomItemPrice[index]) + Number(result.product_price)).toFixed(2) : 0}</span>
												{/* <br/><span><strong>
														{(result.reward == 1) ? 'Custom Points' : 'Custom Price'}:</strong>
														{(result.reward == 0) ? '$' + parseFloat(ShowCustomItemPrice[index]).toFixed(2) 
														: ShowCustomItemPrice[index] + ' pts' } 
													</span> */}


											</div>
										</div>
									</div>
								}) : ""}

								{
									(recentDatas?.data && recentDatas?.data.length == 0) ? <div className="col-lg-12 col-md-12" style={{ marginTop: '30px', textAlign: '-webkit-center' }}>
										<div className='col-md-6'>
											<div className="item-box shop-item style2">
												<div className="item-img">
													<img src={NoOrderFound} alt="" />
												</div>
											</div>
										</div>
									</div> : ""
								}

							</div>
						</div>
						{(recentDatas && recentDatas?.data && recentDatas?.data.length) ?
							<div className="row">
								<div className="col-lg-6 col-md-6 m-b30">

								</div>
								<div className="col-lg-6 col-md-6">
									<h3>Order Details</h3>
									<table className="table-bordered check-tbl">
										<tbody>
											<tr>
												<td>Subtotal</td>
												<td>${(recentDatas && recentDatas.data.length) ? parseFloat(Number(recentDatas?.data[0].order_amount) - Number(recentDatas?.data[0].tax_amount)).toFixed(2) : 0}</td>
											</tr>
											<tr>
												<td>Reward Points</td>
												<td>{(recentDatas && recentDatas.data.length) ? recentDatas?.data[0].redeem_points : 0} pts</td>
											</tr>
											<tr>
												<td>Sales Tax ({(recentDatas && recentDatas.data.length) ? recentDatas?.data[0].tax_percent : 0} %) </td>
												<td>{(recentDatas && recentDatas.data.length) ? '$' + recentDatas?.data[0].tax_amount : 0}</td>
											</tr>
											{
												(recentDatas?.data[0].gift_card !== null) ? <tr>
													<td>Gift Card {"(" + recentDatas?.data[0].gift_card + ")"}</td>
													<td> {'$ ' + parseFloat(recentDatas?.data[0].gift_point).toFixed(2)}</td>
												</tr>
													: ''
											}
											{/* <tr>
												<td>Coupon</td>
												<td>$0.00</td>
											</tr> */}
											<tr>
												<td>Order Total</td>
												<td>${(recentDatas && recentDatas.data.length) ? recentDatas?.data[0].order_total : 0}</td>
											</tr>
										</tbody>
									</table>

								</div>
							</div> : ''}
					</div>

				</div>

			</div>
			<Modal className='dialog-screen modal-dialog1' size="lg" show={showModal} onHide={onCloseCart}>
				<Modal.Header closeButton>
					<Modal.Title>Re-Order Confirmation</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to reorder?
				</Modal.Body>
				<Modal.Footer>

					<Button variant="primary" onClick={ReorderConfirmation} >
						Yes
					</Button>
					<Button variant="secondary" onClick={onCloseCart}>
						No
					</Button>
				</Modal.Footer>
			</Modal>

			<Footer />
		</>
	)
	// }
}


export default OrderView;