import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { register } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "signup";

const initialState = {
    loading: "initial",
    forgotloading: "initial",
    resetloading: "initial",
    errorMessage: null,
    signupDatas: null,
};

export const signupData = createAsyncThunk(
    `${namespace}/signup`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, 'register reducer');
            //let payload = EncryptDecrypt.encryptdata(postData, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
            const data = await register(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);


const signupSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [signupData.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [signupData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log({ payload });
            state.signupDatas = payload?.data;
            // localStorage.setItem('authToken',payload?.data?.accessToken)
            // localStorage.setItem('isAuthenticated',false)
            // localStorage.setItem('username',payload?.data?.username)
        },
        [signupData.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.message;
        }
    },
});

export const { clearData } = signupSlice.actions;

export const signupSelector = (state) => state.signup;

export default signupSlice.reducer;
