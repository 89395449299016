import React, { useEffect, useState, useRef } from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import { updatePassword, profileSelector } from "../../store/reducer/profile";
import { API_STATUS } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import { logOut } from "../../store/reducer/login"
import SimpleReactValidator from 'simple-react-validator';

const ChangePassword = ({ history }) => {
    const dispatch = useDispatch();
    let userDetails = JSON.parse(localStorage.getItem('userdata'));
    const [loginUser] = useState(userDetails.id);
    const [formDetails, setFormDetails] = useState({ password: '', confirmpassword: '' });
    const { passwordMessage, passwordloading } = useSelector(profileSelector);
    const formValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [PasswordType, setPasswordType] = useState('password');
	const [NewPasswordType, setNewPasswordType] = useState('password');
    const onPasswordUpdate = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        let postData = formDetails;
        postData.id = loginUser;

        if (formValidator.current.allValid()) {
            dispatch(updatePassword({ postData }));
        }
        // if (postData.password === '' || postData.password.trim() === '') {
        //     toast.error('Password is required.!');
        //     return false;
        // } else {
        //     if (postData.password.length <= 7) {
        //         toast.error("Password must be 8 characters in length");
        //         return false;

        //     } else if (/^\S{3,}$/.test(postData.password) == false) {
        //         toast.error("Password should not allow spaces");
        //         return false;
        //     }
        // }

        // if (postData.confirmpassword === '') {
        //     toast.error('Confirm Password is required.!');
        //     return false;
        // }

        // if (postData.password !== postData.confirmpassword) {
        //     toast.error("Password and confirm password didn't match");
        //     return false;
        // }
        // if (postData.password.trim().includes(' ')) {
        //     toast.error("Password shouldn't allow blank spaces");
        //     return false;
        // }



        // alert(/^\S{3,}$/.test(postData.password))

    };

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    useEffect(() => {

        if (passwordloading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Password changed successfully!");
            dispatch(logOut());
            window.setTimeout(function () {
                window.location.href = '/login'
            }, 1000);
        }
        if (passwordloading === API_STATUS.PENDING) {
            toast.dismiss();
        }
        if (passwordloading === API_STATUS.REJECTED) {
            toast.dismiss();
            toast.error(passwordMessage);
        }
    }, [passwordloading]);

    const togglePassword = () => {
		if (PasswordType == 'text') {
			setPasswordType('password')
		} else {
			setPasswordType('text')

		}
	}

	const toggleNewPassword = () => {
		if (NewPasswordType == 'text') {
			setNewPasswordType('password')
		} else {
			setNewPasswordType('text')

		}
	}

    return (
        <>

            <Header />
            <div className="page-content bg-white">

                <div className="section-full content-inner-2 shop-account">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="p-a30 border-1 max-w500 m-auto radius-sm">
                                    <div className="tab-content">
                                        <form id="login" className="tab-pane active">
                                            <h3 className="m-b5">Change Password</h3>
                                            <div className="form-group">
                                                <label>Password <span style={{ color: 'red' }}>*</span></label>
                                                <input
                                                    className="form-control"
                                                    id="signin-password"
                                                    placeholder="Password"
                                                    type={PasswordType}
                                                    value={formDetails.password}
                                                    autoComplete="off"
                                                    onChange={val => {
                                                        setFormDetails({ ...formDetails, password: val.target.value });
                                                    }}
                                                />
                                               
                                                {formValidator.current.message(
                                                    'password',
                                                    formDetails.password,
                                                    ['required',
                                                        {
                                                            regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!/%*?&])[A-Za-z\d@$!/%*?&]{8,15}$/
                                                        }
                                                    ],
                                                    {
                                                        className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Please Enter Password',
                                                            password: 'Please enter valid password',
                                                            regex: 'Minimum 8 and Maximum 15 characters, at least include one Uppercase letter, one Lowercase letter, one Number and one Special character (@$!%*?&)',
                                                        }
                                                    }
                                                )}
                                                 <span style={{ position: 'absolute', left: "51rem", 'top': '7.3rem'  } }>
                                                    {(PasswordType === 'password') ? <i onClick={togglePassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
                                                        < i onClick={togglePassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
                                                </span>
                                            </div>
                                            <div className="form-group">
                                                <label>Confirm Password <span style={{ color: 'red' }}>*</span></label>
                                              <div className='position-relative'>
                                              <input
                                                    className="form-control"
                                                    id="signin-password"
                                                    placeholder="Password"
                                                    type={NewPasswordType}
                                                    value={formDetails.confirmpassword}
                                                    autoComplete="off"
                                                    onChange={val => {
                                                        setFormDetails({ ...formDetails, confirmpassword: val.target.value });
                                                    }}
                                                />
                                                 {/* < i onClick={toggleNewPassword} class="fa fa-eye-slash position-absolute" aria-hidden="true" style={{ cursor: 'pointer',top: '13px', right: '20px' }} /> */}
                                                 {(NewPasswordType === 'password') ? <i onClick={toggleNewPassword} class="fa fa-eye position-absolute" aria-hidden="true" style={{ cursor: 'pointer', top: '13px', right: '20px'}} /> :
                                                        < i onClick={toggleNewPassword} class="fa fa-eye-slash position-absolute" aria-hidden="true" style={{ cursor: 'pointer',top: '13px', right: '20px' }} />}
                                              </div>
                                               
                                                {formValidator.current.message(
                                                    'confirmpassword',
                                                    formDetails.confirmpassword,
                                                    ['required', `in:${formDetails.password}`,
                                                    ],
                                                    {
                                                        className: `invalid_feedback
														  ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Please Enter Confirm Password',
                                                            regex: 'Please Enter Valid Password',
                                                            in: "New Password and Confirm password doesn't match"
                                                        }
                                                    }
                                                )}
                                                 {/* <span style={ (!AddFormSubmit) ? { position: 'absolute', left: "51rem", 'top': '13.7rem' } : { position: 'absolute', left: "51rem", 'top': '18.3rem' }}>
                                                    {(NewPasswordType === 'password') ? <i onClick={toggleNewPassword} class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer', }} /> :
                                                        < i onClick={toggleNewPassword} class="fa fa-eye-slash" aria-hidden="true" style={{ cursor: 'pointer', }} />}
                                                </span> */}
                                            </div>
                                            <div className="text-left">
                                                {/* <button  type="submit" className="btn btnhover">CREATE</button> */}
                                                <a className="btn btn-primary btn-lg btn-block"
                                                    onClick={onPasswordUpdate}
                                                >Update</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default ChangePassword;
