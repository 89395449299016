import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { tryLogin, resetPassword } from "../../services/api";
import { validateToken, tryLogin, forgotPassword, resetPassword } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "login";

const initialState = {
    loading: "initial",
    forgotloading: "initial",
    resetloading: "initial",
    errorMessage: null,
    loginData: null,
    TokenDatas: null,
    TokenLoading: "initial",

};

export const doLogin = createAsyncThunk(
    `${namespace}/doLogin`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData, 'dologin reducer');
            //let payload = EncryptDecrypt.encryptdata(postData, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
            const data = await tryLogin(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const forgotPwd = createAsyncThunk(
    `${namespace}/forgotpassword`,
    async ({ forgotData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(forgotData, 'forgot reducer');
            // let payload = EncryptDecrypt.encryptdata(postData, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')

            const data = await forgotPassword(forgotData);
            console.log("data--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const resetPwd = createAsyncThunk(
    `${namespace}/resetPwd`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        try {
            console.log(postData);
            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await resetPassword(postData);
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const CheckValidateToken = createAsyncThunk(
    `${namespace}/CheckTokenDispatch`,
    async ({ token='' }, { rejectWithValue, dispatch }) => {
        try {
            console.log(token, 'token reducer');
            //let payload = EncryptDecrypt.encryptdata(postData, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
            const data = await validateToken({ token: token });
            console.log("getScanCount--> ", data);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const loginSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        logOut: () => {
            let tempArray = localStorage.getItem('temporaryToken')
            localStorage.clear();
            localStorage.setItem('temporaryToken', tempArray)
            // window.location.reload(true);
        },
    },
    extraReducers: {
        [doLogin.pending](state) {
            state.loading = API_STATUS.PENDING;
        },
        [doLogin.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log({ payload });
            state.loginData = payload?.data;
            localStorage.setItem('authToken',payload?.data?.accessToken)
            localStorage.setItem('isAuthenticated',true)
            localStorage.setItem('username',payload?.data?.username)
            localStorage.setItem('userdata',JSON.stringify(payload?.data))
        },
        [doLogin.rejected](state, action) {
            state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.message;
        },
        [forgotPwd.pending](state) {
            state.forgotloading = API_STATUS.PENDING;
        },
        [forgotPwd.fulfilled](state, { payload }) {
            state.forgotloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [forgotPwd.rejected](state, action) {
            state.forgotloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [resetPwd.pending](state) {
            state.resetloading = API_STATUS.PENDING;
        },
        [resetPwd.fulfilled](state, { payload }) {
            state.resetloading = API_STATUS.FULFILLED;
            console.log({ payload });
        },
        [resetPwd.rejected](state, action) {
            state.resetloading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        },
        [CheckValidateToken.pending](state) {
            state.TokenLoading = API_STATUS.PENDING;
        },
        [CheckValidateToken.fulfilled](state, { payload }) {
            state.TokenLoading = API_STATUS.FULFILLED;
            state.TokenDatas = payload?.data;
        },
        [CheckValidateToken.rejected](state, action) {
            state.TokenLoading = API_STATUS.REJECTED;
        },
    },
});

export const { clearData, logOut } = loginSlice.actions;

export const loginSelector = (state) => state.login;

export default loginSlice.reducer;
