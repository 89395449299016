import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  websiteSubCategoryList } from "../../services/api";
import { API_STATUS } from "../../utils/constants";
// import EncryptDecrypt from './../../utils/encrypt&decrypt';
const namespace = "subcategory";

const initialState = {
    loading: "initial",
    errorMessage: null,
    // categoryData: null,
    subcategoryDatas: {},
    subcategoryCount: 0
};

export const subcategoryData = createAsyncThunk(
    `${namespace}/subcategory`,
    async ({ query = "", page = 0, limit = 0, sortby ="", order="", categoryid=0}, { rejectWithValue, dispatch }) => {
        try {
            // console.log("categoryid--> ", categoryid);

            //let payload = EncryptDecrypt.encryptdata(postData, secretKey)
            const data = await websiteSubCategoryList(query, page, limit, sortby, order,categoryid);
            console.log("getCount--> ", data);
            return data;
        } catch (error) {
            console.log("getCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

const subcategorySlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [subcategoryData.fulfilled](state, { payload }) {
            state.loading = API_STATUS.FULFILLED;
            console.log(payload, 'payload');
            // state.subcategoryCount = payload?.count;
            state.subcategoryDatas = payload?.data;
        },
    },
});

export const { clearData } = subcategorySlice.actions;

export const subcategorySelector = (state) => state.subcategory;

export default subcategorySlice.reducer;
